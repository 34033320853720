import React, { useContext, useEffect, useState } from 'react'
import { RetirarDineroContext } from '../context/retirarDineroContext/RetirarDineroContext';
import { UserContext } from '../context/userContext/UserContext';
import { Cuenta, RespGetCuentas } from '../interfaces/getCuentasInterface';
import { AuthContext } from '../context/authContext/AuthContext';
import { TipoAccount } from '../enums/enums';
import { getUserCuentas, getUserData } from '../api/userApi';
import { formatearAccountSearched } from '../helpers/formatearAccountSearched';

const useMenuRetirarDineroHook = () => {

    const listaTipoMoneda = ['PESOS', 'DÓLAR LOCAL'];
    const { setMoneda, moneda } = useContext(RetirarDineroContext);
    const { cuentas, setUserCuentas, setUserData } = useContext(UserContext);
    const [cuentasFiltradas, setCuentasFiltradas] = useState<Cuenta[]>([])
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { accountSearched, accountType, token } = useContext(AuthContext)
    const [loadingCuentas, setLoadingCuentas] = useState(false)

    useEffect(() => {
        cargarCuentasSearched().then((cuentasSearched) => {
            seteoCuentas(cuentasSearched);
        });
    }, [accountSearched])

    const cargarCuentasSearched = async () => {
        setLoadingCuentas(true)
        const accountTypeStorage = localStorage.getItem("accountType");
        let getCuentas
        let respUserData
        try {
            if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                const accountFormateada = formatearAccountSearched(accountSearched)
                respUserData = await getUserData(token, accountFormateada);
                getCuentas = await getUserCuentas(token, accountFormateada);
            } else {
                getCuentas = await getUserCuentas(token);
                respUserData = await getUserData(token);
            }
            // const uniqueCuentas = getCuentas.data.body.cuentas.filter((obj, index, self) =>
            //     index === self.findIndex((o) => o.CuentaBancaria === obj.CuentaBancaria && o.Moneda === obj.Moneda)
            // )
            let uniqueCuentas: Cuenta[] = [];
            getCuentas.data.body.cuentas.forEach(d => {
                const isDuplicate = uniqueCuentas.some(c => {
                    return c['CBU-CVU'] === d['CBU-CVU'] && c.Moneda === d.Moneda;
                });
                if (!isDuplicate) {
                    uniqueCuentas.push(d);
                }
            });
            setUserCuentas(uniqueCuentas);
            setUserData(respUserData.data.body.titular);
            return uniqueCuentas
        } catch (error) {
            console.log(error)
        } finally{
            setLoadingCuentas(false)
        }
    }   

    useEffect(() => {
        seteoCuentas();
    }, [moneda])

    const seteoCuentas = (cuentasSearched?: Cuenta[]) => {
        let filteredCuentas
        if (cuentasSearched) {
            filteredCuentas = cuentasSearched.filter(c => moneda === c.Moneda || (moneda === 'DÓLAR LOCAL' && c.Moneda === 'DOLARES'))
        }else {
            filteredCuentas = cuentas.filter(c => moneda === c.Moneda || (moneda === 'DÓLAR LOCAL' && c.Moneda === 'DOLARES'))
        }
        setCuentasFiltradas(filteredCuentas);
    }

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenuRetirarDinero = (op?: string) => {
        setAnchorEl(null);
        if (op) {
            setMoneda(op);
        }
    };

    return {
        open,
        anchorEl,
        listaTipoMoneda,
        cuentasFiltradas,
        handleOpenMenu,
        handleCloseMenuRetirarDinero,
        loadingCuentas,
    }
}

export default useMenuRetirarDineroHook