import { Menu, MenuItem } from '@mui/material'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';
import { useContext } from 'react';


interface Props {
    open: boolean,
    anchorEl: HTMLElement | null,
    handleCloseMenu: (op?: string | undefined) => void,
    listaMenu: string[],
    actualMenu: string | null | undefined,
}

const MenuPopUp = ({ open, anchorEl, handleCloseMenu, listaMenu, actualMenu }: Props) => {

    const { width } = useWindowDimensions()

    const { primaryColor, grayToDarkGrayBackground, hoverMenuBackrgound } = useContext(ThemeContext);

    const styles = {
        menuItems: {
            backgroundColor: grayToDarkGrayBackground,
            borderBottom: `0.2px solid ${grayToDarkGrayBackground}`,
            width: 'auto',
            borderRadius: '5px',
            color: primaryColor,
            padding: '0.8em 1.5em',
            '&:hover':{
                backgroundColor: `${hoverMenuBackrgound} !important`,
            }
        }
    }

    return (
        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleCloseMenu()}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {
                    marginTop: '2.3em !important',
                    marginLeft: '0.2em !important',
                    borderRadius: '5px',
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    '& ul': {
                        padding: '0px',
                        borderRadius: '10px',
                        marginRight: width > 992 ? '3em' : '2em',
                        // backgroundColor: 'rgba(237, 237, 237, 1)',
                    },
                }
            }}
        >
            {
                listaMenu.map((lista: string) => {
                    return lista !== actualMenu ?
                        (
                            <MenuItem key={lista} sx={styles.menuItems} onClick={() => handleCloseMenu(lista)}>{lista}</MenuItem>
                        ) :
                        null
                })
            }
        </Menu>
    )
}

export default MenuPopUp
