import React from 'react'
import CardTitulos from '../CardTitulos/CardTitulos'
import CardInfoPanel from '../CardInfoPanel/CardInfoPanel'
import { TipoMercado } from '../../../enums/enums'
import { DataEspecie } from '../../../interfaces/mercadoInferfaces';
import { Container } from './elements';
import { TitulosOrdenarPor } from '../../../interfaces/TitulosIntreface';

interface Props {
    tipoMercado: TipoMercado;
    filtrado: DataEspecie[] | null | undefined;
    loadingEspecies: boolean;
    actualMenuEspecies: string;
    hayFavoritos: () => boolean;
    cargarDatosOperar: (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => void;
    noHayData: boolean;
    colorMap: {};
    ordenarPorTitulos: (titulo: string) => void;
    ordenarPor:{ titulo: keyof TitulosOrdenarPor, ascendente: boolean };
    cargarPreciosSinSocket: (actualMenu: string) => Promise<void>
}

const CardContainerEspecies = ({
    tipoMercado,
    filtrado,
    loadingEspecies,
    actualMenuEspecies,
    hayFavoritos,
    cargarDatosOperar,
    noHayData,
    colorMap,
    ordenarPorTitulos,
    ordenarPor,
    cargarPreciosSinSocket,
}: Props) => {

    const titulosPrincipalesMercadoLocal = [
        'Especie',
        'Precio',
        '% día',
        'Plazo',
        'Cant. Compra',
        'Precio Compra',
        'Precio Venta',
        'Cant. Venta',
        'Volumen',
    ];

    const titulosPrincipalesMercadoExterior = [
        'Especie',
        'Precio',
        '% día',
    ];


    return (
        <Container>
            <CardTitulos
                titulos={
                    tipoMercado === TipoMercado.Local
                        ? titulosPrincipalesMercadoLocal
                        : titulosPrincipalesMercadoExterior
                }
                ordenarPorTitulos={ordenarPorTitulos}
                ordenarPor={ordenarPor}
            />
            <CardInfoPanel
                dataEspecies={filtrado}
                loading={loadingEspecies}
                actualMenu={actualMenuEspecies}
                hayFav={hayFavoritos}
                cargarDatosOperar={cargarDatosOperar}
                noHayData={noHayData}
                colorMap={colorMap}
                cargarPreciosSinSocket={cargarPreciosSinSocket}
            />
        </Container>
    )
}

export default CardContainerEspecies