export enum TipoMercado {
  Local = 'LOCAL',
  Exterior = 'EXTERIOR',
}

export enum TipoOperacion {
  Compra = 'COMPRA',
  Venta = 'VENTA',
}
export enum TipoAlerta {
  Soporte = 'SOPORTE',
  Resistencia = 'RESISTENCIA',
}
export enum TipoOpcion {
  Actual = 'ACTUAL',
  Historico = 'HISTORICO',
}
export enum TipoAccion {
  Compra = 'COMPRA',
  Venta = 'VENTA',
  Ninguno = 'NINGUNO',
}

export enum LimiteOperacion {
  Limit = "Limit",
  MARKET = "Market"
}

export enum TipoNavigate {
  Home = 'home',
  Mercado = 'mercado',
  Ordenes = 'ordenes',
  Operar = 'operar',
  Movimientos = 'perfil/movimientos',
  Atras = 'atras',
}

export enum TipoSwitcher {
  Home = 'home',
  NavBar = 'navbar',
}

export enum TipoOpcionGrafico {
  Grafico = 'Gráfico',
  Operaciones = 'Operaciones',
}

export enum EstadoTransferencia {
  Realizada = "REALIZADA",
  Denegada = "DENEGADA",
  Procesandose = "PROCESANDOSE"
}

export enum TipoInfoMercado {
  Resultados = "RESULTADOS DEL DÍA",
  Ordenes = "ÓRDENES DEL DÍA",
}

export enum TipoAccount {
  Admin = "admin",
  Normal = "normal",
}

export enum TipoReinversionDolar {
  Local = "Reinversión local",
  Exterior = "Reinversión exterior"
}