import React, { useContext } from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Icon } from '@iconify/react';
import { ContainerCotitulares, ContainerDatosCotitulares, CuentaText, DatosColumn, DatosTitle, DatosTitleCotitulares, FirstRow, IconsContainer, Image, Item, ItemText, ItemTitle, NameEjecutivoText, NameText, RowsContainer, SecondRow, WhiteText } from './elements';
import { UserContext } from '../../context/userContext/UserContext';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderRadius: '0.5em',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
    [theme.breakpoints.up('lg')]: {
        width: '100%',
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        sx={{ display: 'flex', alignItems: 'start', borderRadius: '0.5em', }}
        {...props}
    />
))(({ theme }) => ({
    width: '100%',
    '& .MuiAccordionSummary-expandIconWrapper': {
        marginTop: '2.5em',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('lg')]: {
        width: '100%',

    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '0.5em 0.8em',
    [theme.breakpoints.up('lg')]: {
        padding: '0.5em 5.5em 0.5em',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderTop: '0.1em dashed #EBEBEB',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.2)',
    }
}));

function titleCase(str: string | undefined) {
    if (str !== undefined) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    } else {
        return '';
    }
}

export const AccordionDatos = () => {

    const { userData } = useContext(UserContext);

    const [expanded, setExpanded] = React.useState<string | false>('');
    const { width } = useWindowDimensions();

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const themeContext = useContext(ThemeContext)

    const { primaryColor, primaryBackgroundColor } = themeContext;

    return (
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor }}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.5em', color: primaryColor }} />}>
                <RowsContainer>
                    <FirstRow>
                        <Icon icon="mdi:account-outline" style={{ ...styles.personIconStyle, backgroundColor: primaryColor, color: primaryBackgroundColor }} />
                        <DatosColumn>
                            <NameText>{titleCase(userData?.nombre)}</NameText>
                            <CuentaText>{`Cuenta N° ${userData?.nroCuenta}`}</CuentaText>
                        </DatosColumn>
                    </FirstRow>
                    {
                        width > 992 ?
                            null
                            :
                            <SecondRow>
                                <Image
                                    src={userData?.url_foto_manager}
                                />
                                <DatosColumn>
                                    <WhiteText>Ejecutivo/a de cuenta</WhiteText>
                                    <NameEjecutivoText>{titleCase(userData?.manager)}</NameEjecutivoText>
                                </DatosColumn>
                                <IconsContainer>
                                    <Link to={'tel:+541177001888'} target="_blank" rel="noreferrer">
                                        <Icon icon="heroicons:phone" style={styles.iconContacto} />
                                    </Link>
                                    <Link to={'https://wa.me/5491152197791'} target="_blank" rel="noreferrer">
                                        <Icon icon="ic:baseline-whatsapp" style={styles.iconContacto} />
                                    </Link>
                                </IconsContainer>
                            </SecondRow>
                    }
                </RowsContainer>

            </AccordionSummary>
            <AccordionDetails>
                <div style={{ display: 'flex', flexDirection: width > 992 ? 'row' : 'column' }}>
                    <div>
                        <DatosTitle>Datos personales</DatosTitle>
                        <Item>
                            <ItemTitle>Teléfono</ItemTitle>
                            <ItemText>{userData?.telefono}</ItemText>
                        </Item>
                        <Item>
                            <ItemTitle>Mail</ItemTitle>
                            <ItemText>{userData?.mail}</ItemText>
                        </Item>
                    </div>
                    {userData?.infoCotitulares?.length ?
                        <ContainerCotitulares>
                            <DatosTitleCotitulares>Cotitulares</DatosTitleCotitulares>
                            <ContainerDatosCotitulares>
                                {userData.infoCotitulares.map(cotitulares =>
                                    <Item>
                                        <ItemTitle>{cotitulares.nombre.toLowerCase().split(" ").map(palabra => palabra.charAt(0).toUpperCase() + palabra.slice(1)).join(" ")/*convierte la primer letra de cada palabra en mayusucula*/}</ItemTitle>
                                        <ItemText>{cotitulares?.telefono}</ItemText>
                                        <ItemText>{cotitulares?.email}</ItemText>
                                    </Item>
                                )}
                            </ContainerDatosCotitulares>
                        </ContainerCotitulares>
                        : null
                    }
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

const styles = {
    personIconStyle: {
        height: '2.2em',
        width: '2.2em',
        borderRadius: 100,
        padding: 4,
        marginRight: '1.2em',
        marginTop: '0.5em',
    },
    iconContacto: {
        height: '1.6em',
        width: '1.6em',
        padding: 4,
        color: 'white',
    }
}
