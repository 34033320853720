import { ChangeEvent, createContext, useContext, useEffect, useState } from "react";
import { DatosClaveEspecie, Tipo } from "../../interfaces/nombreEspeciesInterfaces";
import { LimiteOperacion, TipoMercado, TipoOperacion } from "../../enums/enums";
import { AuthContext } from "../authContext/AuthContext";
import { MercadoContext } from "../mercadoContext/MercadoContext";
import { Declaracion } from "../../interfaces/ddjjInterface";
import { getDDJJOperar } from "../../api/operarApi";
import { formatEspeciesExterior } from "../../helpers/formatEspeciesExterior";
import { formatCurrency } from "../../helpers/formatCurrency";
import { getPrecioEspecieCorta } from "../../api/mercadoApi";
import axios, { CancelTokenSource } from "axios";
import { UserContext } from "../userContext/UserContext";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { separacionDecimal } from "../../helpers/separacionDecimal";
import { newSeparacionDecimal } from "../../helpers/newSeparacionDecima";
import { DetalleContext } from "../detalleEspecieContext/DetalleContext";
import { agregarSeparacionMiles, quitarPuntos } from "../../helpers/formatearInputsPrecios";


type OperarContextProps = {
    especieOperada: DatosClaveEspecie | null,
    setEspecieOperada: React.Dispatch<React.SetStateAction<DatosClaveEspecie | null>>,
    actualOption: TipoOperacion | null,
    setActualOption: React.Dispatch<React.SetStateAction<TipoOperacion | null>>,
    cantidad: string,
    setCantidad: React.Dispatch<React.SetStateAction<string>>,
    textoTitulos: string,
    setTextoTitulos: React.Dispatch<React.SetStateAction<string>>,
    precio: string,
    setPrecio: React.Dispatch<React.SetStateAction<string>>,
    importe: string,
    setImporte: React.Dispatch<React.SetStateAction<string>>,
    choiced: DatosClaveEspecie | null,
    setChoiced: React.Dispatch<React.SetStateAction<DatosClaveEspecie | null>>,
    textosDDJJ: Declaracion[] | null,
    disabled: boolean,
    setDisabled: React.Dispatch<React.SetStateAction<boolean>>,
    actualMenuLimit: LimiteOperacion,
    setActualMenuLimit: React.Dispatch<React.SetStateAction<LimiteOperacion>>,
    onChangePrecio(e: ChangeEvent<HTMLInputElement> | string): void,
    onChangeTitulo(e: ChangeEvent<HTMLInputElement> | string): void,
    onChangeCantidad(e: ChangeEvent<HTMLInputElement> | string): void,
    anchorElMenuOperar: HTMLElement | null,
    openMenuSearchOperar: boolean,
    handleCloseMenuOperar: (especie?: DatosClaveEspecie) => void,
    tenenciaTotal: number,
    deskOperar: boolean,
    setDeskOperar: React.Dispatch<React.SetStateAction<boolean>>,
    onChangeImporte(e: ChangeEvent<HTMLInputElement> | string): void,
    cantidadFormateada: string;
    precioFormateado: string;
    importeFormateado: string
}

export const OperarContext = createContext({} as OperarContextProps);


export const OperarProvider = ({ children }: any) => {
    const { tipoMercado } = useContext(MercadoContext);
    const { tokenPublico } = useContext(AuthContext);

    const [especieOperada, setEspecieOperada] = useState<DatosClaveEspecie | null>(null);
    const [actualMenuLimit, setActualMenuLimit] = useState(LimiteOperacion.Limit);

    const [precio, setPrecio] = useState(
        actualMenuLimit === LimiteOperacion.Limit ? '' : '0',
    );
    const [actualOption, setActualOption] = useState<TipoOperacion | null>(null);
    const [cantidad, setCantidad] = useState('');
    const [textoTitulos, setTextoTitulos] = useState('');
    const [textosDDJJ, setTextosDDJJ] = useState<Declaracion[] | null>(null);
    const [importe, setImporte] = useState('');
    const [choiced, setChoiced] = useState<DatosClaveEspecie | null>(null);
    const [disabled, setDisabled] = useState(true);
    const [anchorElMenuOperar, setAnchorElMenuOperar] = useState<null | HTMLElement>(null);
    const openMenuSearchOperar = Boolean(anchorElMenuOperar);
    const [deskOperar, setDeskOperar] = useState(false);

    const { tenencia } = useContext(UserContext);
    const [tenenciaTotal, setTenenciaTotal] = useState(0);

    const [cantidadFormateada, setCantidadFormateada] = useState('')
    const [precioFormateado, setPrecioFormateado] = useState('');
    const [importeFormateado, setImporteFormateado] = useState('');

    const { getOfertasEspecie, setOfertas } = useContext(DetalleContext);
    const { width } = useWindowDimensions();

    //setea todos los valores vacios cuando cambia el mercado
    useEffect(() => {
        setPrecio('')
        setCantidad('')
        setCantidadFormateada('')
        setPrecioFormateado('')
        setImporteFormateado('')
        setTextoTitulos('')
        setDeskOperar(false)
        setActualMenuLimit(LimiteOperacion.Limit)
        setChoiced(null)
        setOfertas(undefined);
    }, [tipoMercado])

    useEffect(() => {
        if (choiced && width < 992) {
            getOfertasEspecie(choiced?.especie_completa ? choiced.especie_completa : choiced.especie)
        }
    }, [choiced])

    useEffect(() => {
        const getDataDDJJ = async () => {
            if (tokenPublico) {
                try {
                    const resp = await getDDJJOperar(tokenPublico);
                    setTextosDDJJ(resp.data.body);
                } catch (error) {
                    console.log(error);
                }
            }
        };
        getDataDDJJ();
    }, [tokenPublico]);

    //si se selecciona la opcion market setea el precio con getUltimoPrecio, sino setea 0
    useEffect(() => {
        if (actualMenuLimit === LimiteOperacion.MARKET) {
            setPrecio('0');
        }
    }, [choiced, actualMenuLimit]);

    //toma el ultimo precio para la opcion Market
    // async function getUltimoPrecio(cancelToken: CancelTokenSource) {
    //     //en exterior solamente porque por ahora market es solo en exterior
    //     try {
    //         if (tipoMercado === TipoMercado.Exterior && tokenPublico && choiced) {
    //             const { data } = await getPrecioEspecieCorta(
    //                 tokenPublico,
    //                 [choiced?.especie],
    //                 cancelToken,
    //             );
    //             let precio = data.body[0].ultimo;
    //             setPrecio(precio.toString())
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         setPrecio('0');
    //     }
    // }


    useEffect(() => {
        getTenenciaEspecie(choiced);
    }, [choiced, textoTitulos, tipoMercado]);

    function getTenenciaEspecie(choiced: DatosClaveEspecie | null) {
        let cantTenencia = 0;
        //si el tipo de mercado es exterior se le agrega _US
        let textBuscado = choiced
            ? choiced.especie
            : tipoMercado === TipoMercado.Exterior
                ? textoTitulos + '_US'
                : textoTitulos;
        // busca la especie en la tenencia y setea la cantidad
        if (tipoMercado === TipoMercado.Local) {
            let espEncontrada = tenencia?.tenenciaMercadoLocal.find(
                e => e.ticker.toLowerCase() === textBuscado.toLowerCase(),
            );
            cantTenencia = espEncontrada ? espEncontrada.cantidad : 0;
        } else {
            let espEncontrada = tenencia?.tenenciaMercadoExterior.find(
                e => e.ticker.toLowerCase() === textBuscado.toLowerCase(),
            );
            cantTenencia = espEncontrada ? espEncontrada.cantidad : 0;
        }
        setTenenciaTotal(cantTenencia);
    }



    const handleCloseMenuOperar = (especie?: DatosClaveEspecie) => {
        //setea el titulo y la especie a operar
        if (especie) {
            setTextoTitulos(formatEspeciesExterior(especie.especie));
            setChoiced(especie)
        }
        setAnchorElMenuOperar(null)
    }

    function onChangePrecio(e: ChangeEvent<HTMLInputElement> | string) {
        //setea el precio
        if (importe !== '') {
            setImporte('')
            setImporteFormateado('')
        }
        if (typeof e === 'string') {
            let precioSinPuntos = quitarPuntos(e)
            setPrecio(precioSinPuntos);
            let precioConPuntos = agregarSeparacionMiles(precioSinPuntos)
            setPrecioFormateado(precioConPuntos)
        } else {
            let precioSinPuntos = quitarPuntos(e.target.value)
            setPrecio(precioSinPuntos);
            let precioConPuntos = agregarSeparacionMiles(precioSinPuntos)
            setPrecioFormateado(precioConPuntos)
        }
    }

    function onChangeTitulo(e: ChangeEvent<HTMLInputElement> | string) {
        //setea el titulo
        if (typeof e === 'string') {
            setTextoTitulos(e);
        } else {
            setTextoTitulos(e.target.value.toLocaleUpperCase());
            setAnchorElMenuOperar(e.currentTarget);
        }
    }

    function onChangeCantidad(e: ChangeEvent<HTMLInputElement> | string) {
        //setea la cantidad        
        if (importe !== '') {
            setImporte('')
            setImporteFormateado('')
        }

        if (typeof e === 'string') {
            let cantidadSinPuntos = quitarPuntos(e)
            setCantidad(cantidadSinPuntos);
            let cantidadConPuntos = agregarSeparacionMiles(cantidadSinPuntos)
            setCantidadFormateada(cantidadConPuntos);
        } else {
            let cantidadSinPuntos = quitarPuntos(e.target.value)
            setCantidad(cantidadSinPuntos)
            let cantidadConPuntos = agregarSeparacionMiles(cantidadSinPuntos)
            setCantidadFormateada(cantidadConPuntos);
        }
    }

    function onChangeImporte(e: ChangeEvent<HTMLInputElement> | string) {
        //setea el importe
        if (cantidad !== '') {
            setCantidad('')
            setCantidadFormateada('');
        }
        if (precio !== '') {
            setPrecio('')
            setPrecioFormateado('')
        }

        if (typeof e === 'string') {
            setImporte(e);
            setImporteFormateado(e);
        } else {
            let importeSinPuntos = quitarPuntos(e.target.value)
            setImporte(importeSinPuntos);
            let importeConPuntos = agregarSeparacionMiles(importeSinPuntos)
            setImporteFormateado(importeConPuntos)
        }
    }



    return (
        <OperarContext.Provider
            value={{
                especieOperada,
                setEspecieOperada,
                actualOption,
                setActualOption,
                cantidad,
                setCantidad,
                textoTitulos,
                setTextoTitulos,
                precio,
                setPrecio,
                importe,
                setImporte,
                choiced,
                setChoiced,
                textosDDJJ,
                disabled,
                setDisabled,
                actualMenuLimit,
                setActualMenuLimit,
                onChangePrecio,
                onChangeTitulo,
                onChangeCantidad,
                anchorElMenuOperar,
                openMenuSearchOperar,
                handleCloseMenuOperar,
                tenenciaTotal,
                deskOperar,
                setDeskOperar,
                onChangeImporte,
                cantidadFormateada,
                precioFormateado,
                importeFormateado,
            }}>
            {children}
        </OperarContext.Provider>
    );
}