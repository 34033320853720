import React, { useCallback, useContext, useEffect, useState } from 'react'
import { DataEspecie, DataEspecieOpciones } from '../../../interfaces/mercadoInferfaces';
import { Container, ContainerSinInfo, MostrarFavs, MostrarFavsAca, NoFavsInfo } from './elements';
import { LoadingContainer } from '../../../elements/loadingContainer';
import { CircularProgress } from '@mui/material';
import { ThemeContext } from 'styled-components';
import CardEspecieLocalNuevo from '../CardEspecieLocalNuevo/CardEspecieLocalNuevo';
import { FixedSizeList as List } from 'react-window';
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { TipoMercado } from '../../../enums/enums';
import { getDataEspecie } from '../../../api/mercadoApi';
import { getFavourites } from '../../../api/userApi';

interface Props {
    dataEspecies: DataEspecie[] | null | undefined;
    loading: boolean;
    actualMenu: string;
    hayFav: () => boolean;
    cargarDatosOperar: (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => void;
    noHayData: boolean;
    colorMap: {};
    cargarPreciosSinSocket: (actualMenu: string) => Promise<void>;
}

const CardInfoPanel = ({
    dataEspecies,
    loading,
    actualMenu,
    hayFav,
    cargarDatosOperar,
    noHayData,
    colorMap,
    cargarPreciosSinSocket,
}: Props) => {

    const [data, setData] = useState<DataEspecie[] | DataEspecieOpciones[] | null | undefined>([])

    const { loadingColor } = useContext(ThemeContext);

    const [mostrarFavs, setMostrarFavs] = useState(false)

    const { tipoMercado } = useContext(MercadoContext);

    const [loadingFavs, setLoadingFavs] = useState(false);

    const { tokenPublico } = useContext(AuthContext);

    const [favoritos, setFavoritos] = useState<DataEspecie[] | null | undefined>([]);

    const obtenerFavsMercadoNuevo = async () => {
        setLoadingFavs(true)
        if (tokenPublico) {
            try {
                const resp = await getFavourites(tokenPublico);
                let arrLocal: string[] = [];
                let arrExterior: string[] = [];
                resp.data.body.favouriteList.forEach(element => {
                    if (element.tipoMercado === 'LOCAL') {
                        arrLocal.push(element.especie);
                    } else {
                        arrExterior.push(element.especie);
                    }
                });
                if (tipoMercado === TipoMercado.Local) {
                    if (arrLocal.length !== 0) {
                        let resp = await getDataEspecie(
                            tokenPublico,
                            arrLocal,
                        );
                        setFavoritos(resp.data.body);
                    }
                } else {
                    if (arrExterior.length !== 0) {
                        let resp = await getDataEspecie(
                            tokenPublico,
                            arrExterior,
                        );
                        setFavoritos(resp.data.body);
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingFavs(false)
            }
        }
    }

    useEffect(() => {
        setMostrarFavs(false)
    }, [tipoMercado, actualMenu])

    useEffect(() => {
        actualMenu === 'FAVORITOS'
            ? setData(favoritos)
            : setData(dataEspecies)
        // setData(dataEspecies) 
    }, [actualMenu, dataEspecies, favoritos])

    const renderRow = useCallback(({ index, style, data }: { index: number, style: React.CSSProperties, data: any }) => {
        const { items, colorMap } = data
        if (items) {
            const item = items[index];
            const color = colorMap[item.especie];
            return (
                <CardEspecieLocalNuevo
                    style={style}
                    especie={item}
                    index={index}
                    cargarDatosOperar={cargarDatosOperar}
                    colorUltimo={color?.ultimo ? color.ultimo : undefined}
                    colorCantCompra={color?.cantCompra ? color.cantCompra : undefined}
                    colorPrecioCompra={color?.precioCompra ? color.precioCompra : undefined}
                    colorPrecioVenta={color?.precioVenta ? color.precioVenta : undefined}
                    colorCantVenta={color?.cantVenta ? color.cantVenta : undefined}
                    arrayLength={items.length}
                />
            );
        }
        return <></>
    }, [data])


    return (
        <Container>
            {
                loading
                    ? <LoadingContainer style={{ height: '100%' }}>
                        <CircularProgress style={{ color: loadingColor }} />
                    </LoadingContainer>
                    : noHayData && actualMenu !== "FAVORITOS"
                        ? <ContainerSinInfo>
                            <MostrarFavs>Por el momento no podemos actualizar los precios en tiempo real. </MostrarFavs>
                            <div style={{ display: 'flex' }}>
                                <MostrarFavs>Por favor</MostrarFavs>
                                <MostrarFavsAca onClick={(e) => {
                                    cargarPreciosSinSocket(actualMenu)
                                }}>hacé click</MostrarFavsAca>
                                <MostrarFavs>para visualizarlos.</MostrarFavs>
                            </div>
                        </ContainerSinInfo>
                        : actualMenu === "FAVORITOS" && !mostrarFavs
                            ? <ContainerSinInfo>

                                <MostrarFavs>Por el momento no podemos actualizar tus "favoritos" en tiempo real. </MostrarFavs>
                                <div style={{ display: 'flex' }}>
                                    <MostrarFavs>Por favor</MostrarFavs>
                                    <MostrarFavsAca onClick={(e) => {
                                        setMostrarFavs(prev => !prev)
                                        obtenerFavsMercadoNuevo()
                                    }}>hacé click</MostrarFavsAca>
                                    <MostrarFavs>para visualizarlos.</MostrarFavs>
                                </div>
                            </ContainerSinInfo>
                            : actualMenu === "FAVORITOS" && loadingFavs
                                ? <LoadingContainer style={{ height: '100%' }}>
                                    <CircularProgress style={{ color: loadingColor }} />
                                </LoadingContainer>
                                : actualMenu === "FAVORITOS" && !data?.length
                                    ? <ContainerSinInfo>
                                        <NoFavsInfo>No tenes ningún favorito asignado.</NoFavsInfo>
                                    </ContainerSinInfo>
                                    : data && data.length > 0 && (
                                        <List
                                            height={1000} // Altura del contenedor
                                            itemCount={data.length} // Número de elementos
                                            itemSize={20} // Altura de cada elemento
                                            width={'100%'} // Ancho del contenedor
                                            overscanCount={20}
                                            style={{
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                                // flexGrow: 1
                                            }}
                                            itemData={{ items: data, colorMap }}
                                        >
                                            {renderRow}
                                        </List>
                                    )
            }
        </Container >
    )
}

export default CardInfoPanel