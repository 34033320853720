import React, { useContext, useState } from "react";
import { AuthContext } from "../context/authContext/AuthContext";
import { getTenencia, retirarDolares } from "../api/userApi";
import { UserContext } from "../context/userContext/UserContext";
import { formatearAccountSearched } from "../helpers/formatearAccountSearched";
import { TipoAccount } from "../enums/enums";

const useRetirarDolaresHook = () => {
    const { tokenPublico, accountType, accountSearched} = useContext(AuthContext);
    const { setTenencia } = useContext(UserContext);

    const [tipoDolarImporte, setTipoDolarImporte] = useState({
        cantidad: 0,
        especie: "",
    });

    const [tipoConversion7000, setTipoConversion7000] = useState("")

    const transferirDolares = async () => {
        try {
        if (tokenPublico) { 
            let resp
            let tenencia
            const accountTypeStorage = localStorage.getItem("accountType");
            if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin){      
                const accountFormateada = formatearAccountSearched(accountSearched)
                resp = await retirarDolares({
                token: tokenPublico,
                ...tipoDolarImporte,
                tipoDolar: tipoConversion7000,
                accountSearched: accountFormateada
            });
            tenencia = await getTenencia(tokenPublico, accountFormateada);
            setTenencia(tenencia.data.body);
        } else {
            resp = await retirarDolares({
            token: tokenPublico,
            ...tipoDolarImporte,
            tipoDolar: tipoConversion7000,
            });
            tenencia = await getTenencia(tokenPublico);
            setTenencia(tenencia.data.body);
        }
        if (resp.data.error === false) {
        }
        setTipoConversion7000("")
        }
        } catch (error) {
        console.log(error);
        }
    };
    return {
        setTipoDolarImporte,
        transferirDolares,
        setTipoConversion7000,
        tipoConversion7000,
    };
};

export default useRetirarDolaresHook;
