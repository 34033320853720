import { useContext, useState } from 'react'
import { EstadoTransferencia, TipoAccount, TipoNavigate } from '../enums/enums';
import { AuthContext } from '../context/authContext/AuthContext';
import { retirarDinero } from '../api/userApi';
import { useNavigationHook } from './useNavigationHook';
import axios, { AxiosError } from 'axios';
import { ErrorResponseRetirarDinero } from '../interfaces/respRetirarDineroInterface';
import { RetirarDineroContext } from '../context/retirarDineroContext/RetirarDineroContext';
import { formatearAccountSearched } from '../helpers/formatearAccountSearched';

export const useRetirarDinero = () => {

    const { importeRetirar, cuentaBancariaSeleccionada, moneda } = useContext(RetirarDineroContext);
    const [estadoTransferencia, setEstadoTransferencia] = useState<null | EstadoTransferencia>(null);
    const [error, setError] = useState('');
    const { tokenPublico, accountType, accountSearched} = useContext(AuthContext);

    const { handleNavigate } = useNavigationHook();    

    // saca la palabra banco de las cuentas que la tenian. 
    const cutBankName = (banco: string): string => {
        const indexSplit = banco.split('').indexOf('-')
        const cutBank = banco.slice(0, indexSplit);
        if (cutBank.split(' ')[0] === 'BANCO') {
            return cutBank.slice(6);
        }
        return cutBank
    }

    // saca el cuit de la cuenta del banco seleccionada
    const getCuit = (banco: string): string => {
        const indexSplit = banco.split('').indexOf('-');
        const cuit = banco.slice(indexSplit + 1);
        return cuit.replaceAll('/', '-');
    }

    const confirmaRetirarDinero = async () => {
        const accountTypeStorage = localStorage.getItem("accountType");
        setEstadoTransferencia(EstadoTransferencia.Procesandose);
        const tipoMoneda = moneda === 'DÓLAR LOCAL' ? 'dolar' : 'peso';
        if (tokenPublico && cuentaBancariaSeleccionada && importeRetirar) {
            try {
            if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                const resp = await retirarDinero(tokenPublico, importeRetirar.replace(',', '.'), cuentaBancariaSeleccionada['CBU-CVU'], tipoMoneda, formatearAccountSearched(accountSearched))
                } else {
                const resp = await retirarDinero(tokenPublico, importeRetirar.replace(',', '.'), cuentaBancariaSeleccionada['CBU-CVU'], tipoMoneda)
                }
                setEstadoTransferencia(EstadoTransferencia.Realizada);
                close();
            } catch (error: any | AxiosError) {
                if (axios.isAxiosError(error) && error.response) {
                    const responseData: ErrorResponseRetirarDinero = error.response.data as ErrorResponseRetirarDinero;
                    setError(responseData.body)
                    setEstadoTransferencia(EstadoTransferencia.Denegada);
                    close();
                } else {
                    console.log(error);
                    setEstadoTransferencia(EstadoTransferencia.Denegada);
                    close();
                }
            }
        }
    }

    function close() {
        setTimeout(() => {
            handleNavigate(TipoNavigate.Home);
        }, 4000);
    }

    return {
        cutBankName,
        getCuit,
        confirmaRetirarDinero,
        estadoTransferencia,
        error,
    }
}
