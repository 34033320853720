import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader'
import { Container, ContainerHeader, ContainerInfo, ContainerSinMovimientos, LoadingContainer, SecondContainer, TextoSinMovimientos } from '../Movimientos/elements'
import HeaderMovimientos from '../../components/MovimientosComponents/HeaderMovmientos/HeaderMovimientos'
import { useMovimientosHook } from '../../hooks/useMovimientosHook'
import TitulosMovimientos from '../../components/MovimientosComponents/TitulosMovimientos/TitulosMovimientos'
import { CircularProgress } from '@mui/material';
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import TablaMovimientos from '../../components/MovimientosComponents/TablaMovimientos/TablaMovimientos'

const MovimientosTitulos = () => {

    const {
        fechaDesde,
        fechaHasta,
        setFechaDesde,
        setFechaHasta,
        buscarMovimientos,
        dataEspecies,
        setEspecieSeleccionada,
        loadingMovimientos,
        movimientosFiltrados,
        formatearCadena,
        traerDocumentos,
        loadingDescarga,
        especieBuscada,
        mensajeTitulos,
        exportarAExcel
    } = useMovimientosHook('Movimientos titulos');

    const { loadingColor } = useContext(ThemeContext);

    return (
        <Container>
            <ResponsiveHeader title='Movimientos de títulos' showSwitch={false} />
            <SecondContainer>
                <ContainerHeader>
                    <HeaderMovimientos
                        fechaDesde={fechaDesde}
                        fechaHasta={fechaHasta}
                        setFechaDesde={setFechaDesde}
                        setFechaHasta={setFechaHasta}
                        buscarMovimientos={buscarMovimientos}
                        especies={dataEspecies}
                        setEspecieSeleccionada={setEspecieSeleccionada}
                        exportarAExcel={exportarAExcel}
                    />
                </ContainerHeader>
                <ContainerInfo>
                    <TitulosMovimientos />
                    {
                        loadingMovimientos ?
                            <LoadingContainer>
                                <CircularProgress style={{ color: loadingColor }} />
                            </LoadingContainer>
                            : mensajeTitulos.length
                                ? <ContainerSinMovimientos>
                                    <TextoSinMovimientos>
                                        {mensajeTitulos}
                                    </TextoSinMovimientos>
                                </ContainerSinMovimientos>
                                : movimientosFiltrados.length
                                    ? <TablaMovimientos
                                        movimientosFiltrados={movimientosFiltrados}
                                        formatearCadena={formatearCadena}
                                        traerDocumentos={traerDocumentos}
                                        loadingDescarga={loadingDescarga}
                                        especieSeleccionada={especieBuscada}
                                    />
                                    : <ContainerSinMovimientos>
                                        <TextoSinMovimientos>
                                            No hay movimientos para mostrar
                                        </TextoSinMovimientos>
                                    </ContainerSinMovimientos>
                    }
                </ContainerInfo>
            </SecondContainer>
        </Container>
    )
}

export default MovimientosTitulos