import styled from "styled-components";

export const Container = styled.div`
margin-top: 0.3em; 
min-height: 7em; 
// max-height: 8em;
flex: 1;
display: flex; 
flex-direction: column; 
border-radius: 10px;
`

export const ContainerSecundario = styled.div`
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px;
    margin-top: 0.2em;
    overflow-y: scroll;
    // flex-grow: 1;
    scrollbar-width: none;
    ::-webkit-scrollbar {
    display: none;
    }
`;

export const Titulo = styled.h4`
    color: ${({ theme }) => theme.primaryColor};
    margin: 0;
    margin-left: 0.4em;   
`;

export const TitulosSecundarios = styled.h6`
    color: ${({ theme }) => theme.primaryColor};
    text-align: end;
    margin: 0;
`;

export const ContainerTitulos = styled.div`
    display: grid;
    grid-template-columns: 1fr repeat(4, 1.5fr);
    padding: 0em 0.5em 0 0.5em;
    gap: 0.3em;
`

export const SinInfo = styled.p`
    color: ${({ theme }) => theme.accentColor};
    padding: 0em 1em;
    margin: 1.5em 0;
`

export const ContainerSinInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
`