import styled from "styled-components";
import { colors } from "../../theme/Themes";

export const Container = styled.div`
    border-bottom: 0.1em solid #EBEBEB;
`;

export const RowsContainer = styled.div`
    display: flex; 
    flex-direction: column;
    margin-top: 0.5em;
    width: 100%;
`;

export const FirstRow = styled.div`
    display: flex; 
    margin-bottom: 1em;
`;

export const DatosColumn = styled.div`
    display: flex; 
    flex-direction: column;
`;

export const SecondRow = styled.div`
    display: flex; 
    height: 3.5em;
    width: 110%;
    background-color: ${colors.blueRava};
    border-radius: 0.3em;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 992px) {
        width: auto;
        margin-top: 1em;
        border-radius: 10px;
        height: 4em;
        padding: 1em;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }
`;

export const NameText = styled.p`
    font-size: 1.5em;
    margin: 0;
    font-weight: bold;
    @media (min-width: 992px){
        font-size: 1.7em; 
    }
`;

export const CuentaText = styled.p`
    font-size: 1em;
    margin: 0;
    font-weight: bold;
    @media (min-width: 992px){
        font-size: 1.1em; 
    }
`;

export const WhiteText = styled.p`
    font-size: 1.1em;
    margin: 0;
    color: white;
    font-weight: 300;
    @media (min-width: 992px) {
        font-weight: 500;
        font-size: 1.2em;
        margin-left: 0.5em;
    }
`;

export const NameEjecutivoText = styled.p`
    font-size: 1em;
    margin: 0;
    color: white;
    font-weight: 400;
    @media (min-width: 992px) {
        font-weight: 300;
        font-size: 1em;
        margin-left: 0.5em;
    }
`;

export const IconsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-right: 0.5em;
    margin-left: 0.7em;
`
export const DatosTitle = styled.p`
    font-size: 1.2em;
    margin: 0 0 0.5em 0;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 700;
    @media (min-height: 730px) {
        font-size: 1.5em;
    }
    @media (min-width: 992px){
        font-size: 1.1em;
        margin: 0 0 0.1em 0;
    }
`;

export const Item = styled.div`
    border-bottom: 0.1em dashed #EBEBEB;
    @media (min-width: 992px){
        border-bottom: none;
    }
`
export const ItemTitle = styled.p`
    font-size: 1em;
    margin: 0.1em 0 0em 0;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 400;
    @media (min-height: 730px) {
        font-size: 1.3em;
        margin: 0.1em 0 0.2em 0;
    }
    @media (min-width: 992px){
        font-weight: 300;
        font-size: 1.2em;
        margin: 0 0 0 0;
    }
`;

export const ItemText = styled.p`
    font-size: 1em;
    margin: 0 0 0.2em 0;
    color: ${({ theme }) => theme.fontColorWhiteToBlack};
    font-weight: 400;
    @media (min-height: 730px) {
        font-size: 1.1em;
        margin: 0 0 0.5em 0;
    }
    @media (min-width: 992px){
        margin: 0 0 0 0;
    }
`;

export const Image = styled.img`
    height: 2.2em;
    width: 2.2em;
    margin: 0 0.2em 0 0.6em;
    border-radius: 2em;
    @media (min-width: 992px) {
        height: 3em;
        width: 3em;
    }
`
export const ContainerDatosCotitulares = styled.div`
    @media (min-width: 992px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5em 2em;
    }
`

export const ContainerCotitulares = styled.div`
    @media (min-width: 992px) {
        padding-left: 9em;
    }
`

export const DatosTitleCotitulares = styled.p`
    font-size: 1.2em;
    margin: 0.5em 0 0em 0;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 700;
    @media (min-height: 730px) {
        font-size: 1.5em;
    }
    @media (min-width: 992px){
        font-size: 1.1em;
        margin: 0 0 0.1em 0;
    }
`;