import { useContext, useEffect, useState } from "react";
import { getTenencia } from "../api/userApi";
import {
  TenenciaMercadoExterior,
  TenenciaMercadoLocal,
} from "../interfaces/tenenciaInterface";
import { MercadoContext } from "../context/mercadoContext/MercadoContext";
import { TipoAccount, TipoMercado } from "../enums/enums";
import { UserContext } from "../context/userContext/UserContext";
import { AuthContext } from "../context/authContext/AuthContext";
import { formatearAccountSearched } from "../helpers/formatearAccountSearched";

const useInfoMercadoHook = () => {
  const [tenenciaHook, setTenenciaHook] = useState<
    TenenciaMercadoLocal[] | TenenciaMercadoExterior[] | undefined
  >();
  const { tipoMercado } = useContext(MercadoContext);
  const { tenencia, setTenencia, setCartera, loadingTenencia, setLoadingTenencia } = useContext(UserContext);
  const { accountType, accountSearched } = useContext(AuthContext);

  useEffect(() => {
    cargarInfo();
  }, [accountSearched]);


  useEffect(() => {
    tipoMercado === TipoMercado.Local
      ? setTenenciaHook(tenencia?.tenenciaMercadoLocal)
      : setTenenciaHook(tenencia?.tenenciaMercadoExterior);
  }, [tipoMercado]);

  const cargarInfo = async () => {
    try {
      setLoadingTenencia(true)
      const tokenStorage = localStorage.getItem("token");
      const accountTypeStorage = localStorage.getItem("accountType");
      if (tokenStorage) {
        let resp
        if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin){
          resp = await getTenencia(tokenStorage, formatearAccountSearched(accountSearched));
        } else {
          resp = await getTenencia(tokenStorage);
        }
        setTenencia(resp.data.body)
        setCartera(resp?.data?.body?.cartera);
        tipoMercado === TipoMercado.Local
          ? setTenenciaHook(
              resp.data.body.tenenciaMercadoLocal.sort((a, b) => {
                if (a.ticker < b.ticker) return -1;
                if (a.ticker > b.ticker) return 1;
                return 0;
              })
            )
          : setTenenciaHook(
              resp.data.body.tenenciaMercadoExterior.sort((a, b) => {
                if (a.ticker < b.ticker) return -1;
                if (a.ticker > b.ticker) return 1;
                return 0;
              })
            );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTenencia(false)
    }
  };

  return {
    tenenciaHook,
    loadingTenencia,
  };
};

export default useInfoMercadoHook;
