import { useContext } from 'react'
import {
    BotonConfirmar,
    ButtonCancelar,
    Container,
    ContainerButtons,
    ContainerTexts,
    ContainerTitle,
    ExitoErrorContainer,
    ExitoErrorText,
    ExitoErrorTitle,
    SecondContainer,
    Texto,
    TextoMoneda,
    Title
} from './elements'
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { CircularProgress } from '@mui/material';
import { RetirarDineroContext } from '../../../context/retirarDineroContext/RetirarDineroContext';
import { UserContext } from '../../../context/userContext/UserContext';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { useNavigationHook } from '../../../hooks/useNavigationHook';
import { EstadoTransferencia, TipoNavigate } from '../../../enums/enums';
import { LoadingContainer } from '../../../elements/loadingContainer';
import { ThemeContext } from 'styled-components';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useRetirarDinero } from '../../../hooks/useRetirarDinero';
import { ResponsiveHeader } from '../../HeadersComponents/ResponsiveHeader';


export const ConfirmarScreenRetirar = () => {

    const { width } = useWindowDimensions();

    const { cuentaBancariaSeleccionada, moneda, importeRetirar } = useContext(RetirarDineroContext);

    const { cutBankName, getCuit, confirmaRetirarDinero, estadoTransferencia, error } = useRetirarDinero();

    const { userData } = useContext(UserContext);

    const { handleNavigate } = useNavigationHook();

    const { loadingColor, primaryColor, rojoPorcentaje } = useContext(ThemeContext);

    return (
        <Container>

            <ResponsiveHeader title='Retirar dinero' showSwitch={false} />

            {
                width < 992 ?
                    <ContainerTitle>
                        <Title>Confirmación de transferencia</Title>
                    </ContainerTitle>
                    : null
            }

            {estadoTransferencia === null ?
                (
                    <div style={{ paddingTop: '0.5em' }}>
                        <SecondContainer>
                            {
                                width > 992 ?
                                    <ContainerTitle>
                                        <Title>Confirmación de transferencia</Title>
                                    </ContainerTitle>
                                    : null
                            }
                            <ContainerTexts>
                                {cuentaBancariaSeleccionada ?
                                    <>
                                        <Texto>Cuenta destino: {`****${cuentaBancariaSeleccionada?.CuentaBancaria.slice(-4)}`}</Texto>
                                        <Texto>Banco: {cutBankName(cuentaBancariaSeleccionada?.Banco)}</Texto>
                                        <Texto>Titular: {userData?.nombre}</Texto>
                                        <Texto>CUIT: {getCuit(cuentaBancariaSeleccionada.Banco)}</Texto>
                                        <Texto>CBU: {`****${cuentaBancariaSeleccionada['CBU-CVU'].slice(-4)}`}</Texto>
                                        <div style={{ display: 'flex' }}>
                                            <Texto>Transferencia en </Texto>
                                            <TextoMoneda>{moneda}</TextoMoneda>
                                        </div>
                                        <Texto>Importe: {formatCurrency(importeRetirar.replace(',', '.'))} </Texto>
                                    </>
                                    :
                                    null
                                }
                            </ContainerTexts>
                        </SecondContainer>
                        <ContainerButtons>
                            <BotonConfirmar onClick={() => confirmaRetirarDinero()}>CONFIRMAR</BotonConfirmar>
                            <ButtonCancelar onClick={() => handleNavigate(TipoNavigate.Home)}>CANCELAR</ButtonCancelar>
                        </ContainerButtons>
                    </div>
                )
                : estadoTransferencia === EstadoTransferencia.Procesandose ?
                    (
                        <div style={{ paddingTop: '0.5em' }}>
                            <SecondContainer>
                                <LoadingContainer>
                                    <CircularProgress style={{ color: loadingColor }} />
                                </LoadingContainer>
                            </SecondContainer>
                        </div>
                    )
                    : estadoTransferencia === EstadoTransferencia.Realizada ?
                        (
                            <div style={{ paddingTop: '0.5em' }}>
                                <ExitoErrorContainer>
                                    <TaskAltIcon sx={{ fontSize: '50px', color: primaryColor, alignSelf: 'center' }} />
                                    <ExitoErrorTitle style={{ color: primaryColor }}>¡Listo!</ExitoErrorTitle>
                                    <ExitoErrorText style={{ color: primaryColor }}>Tu pedido de transferencia <br></br> se envió correctamente</ExitoErrorText>
                                </ExitoErrorContainer>
                            </div>
                        )
                        :
                        (
                            <div style={{ paddingTop: '0.5em' }}>
                                <ExitoErrorContainer>
                                    <CancelOutlinedIcon sx={{ fontSize: '50px', color: rojoPorcentaje, alignSelf: 'center' }} />
                                    <ExitoErrorTitle style={{ color: rojoPorcentaje }}>Se produjo un error al <br></br> generar la orden.</ExitoErrorTitle>
                                    <ExitoErrorText style={{ color: rojoPorcentaje }}>{error ? error : 'Comunicate con tu asesor/a'}</ExitoErrorText>
                                </ExitoErrorContainer>
                            </div>
                        )
            }
        </Container>
    )
}