import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import ModalConfirmacion from '../../Modal/ModalConfirmacion';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

interface Props {
    esFav: boolean;
    agregarFav: () => Promise<void>;
    openFav: boolean;
    handleOpenFav: () => void;
    handleCloseFav: () => void;
    handleAceptarModalFav: () => void;
    nombreEspecie: string;
    deleteFav: () => Promise<void>;
}

const StarFavoritos = ({
    esFav,
    agregarFav,
    openFav,
    handleOpenFav,
    handleCloseFav,
    handleAceptarModalFav,
    nombreEspecie,
    deleteFav,
}: Props) => {

    const { primaryColor } = useContext(ThemeContext)

    const { width } = useWindowDimensions()
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {esFav ?
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StarRoundedIcon
                        onClick={() => deleteFav()}
                        sx={{
                            color: primaryColor,
                            padding: "0px",
                            fontSize: width < 1300 ? "1em" :  '1.1em',
                            marginLeft: "0.3em",
                            cursor: 'pointer'
                        }} />
                    {/* <ModalConfirmacion
                        open={openFav}
                        handleClose={handleCloseFav}
                        handleConfirmarModal={handleAceptarModalFav}
                        tituloModal={'Eliminar'}
                        textoModal={`¿Quieres eliminar ${nombreEspecie} de tus favoritos?`}
                        textoConfirmarModal={'Eliminar'}
                    /> */}
                </div>
                :
                <StarBorderRoundedIcon
                    onClick={() => agregarFav()}
                    sx={{
                        color: primaryColor,
                        padding: "0px",
                        fontSize: width < 1300 ? "1em" :  '1.1em',
                        marginLeft: "0.3em",
                        cursor: 'pointer'
                    }} />
            }
        </div>
    )
}

export default StarFavoritos