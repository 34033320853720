import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px, rgba(0, 0, 0, 0.12) 0px 2px 1px,
    rgba(0, 0, 0, 0.2) 0px 1px 3px;
    margin-top: 0.3em;
    // overflow: hidden;
    min-height: 7.5em;
    max-height: 7.5em;
    display: flex;
    flex: 1;
    margin-top: 0.5em;
    flex-direction: column;
`;

export const Titulo = styled.h4`
    color: ${({ theme }) => theme.primaryColor};
    text-align: center;
    margin: 0;
`;
export const TitulosSecundarios = styled.h6`
    color: ${({ theme }) => theme.primaryColor};
    text-align: end;
    margin: 0;
`;

export const SecondaryContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0 0.5em 0 0em;
`;

export const TextoDatos = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    font-size: 12px;
    text-align: end;
    margin: 0;
    cursor: pointer;
`;

export const SinOfertas = styled.p`
    text-align: center;
    color: ${({ theme }) => theme.accentColor};
    margin: 0;
`;

export const ContainerSinOfertas = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 1em;
`