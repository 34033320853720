import React, { useContext, useEffect, useState } from 'react'
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { LimiteOperacion, TipoOperacion, TipoReinversionDolar } from '../enums/enums';
import { OperarContext } from '../context/operarContext/OperarContext';


export const useMenuOperarHook = (seteadorDolar?: React.Dispatch<React.SetStateAction<string>>, openModalDola?: (() => void)) => {

    const { setActualMenuLimit, setActualOption } = useContext(OperarContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const listaMenuOperaciones = [TipoOperacion.Compra, TipoOperacion.Venta];
    const listaMenuLimit = [LimiteOperacion.Limit, LimiteOperacion.MARKET];
    const listaTipoPedidoDolar7000 = [TipoReinversionDolar.Local, TipoReinversionDolar.Exterior]

    const open = Boolean(anchorEl);
    const { tipoMercado } = useContext(MercadoContext);

    useEffect(() => {
        setActualMenuLimit(LimiteOperacion.Limit);
    }, [tipoMercado])

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenuLimit = (op?: string) => {
        setAnchorEl(null);
        if (op) {
            op === LimiteOperacion.Limit ?
                setActualMenuLimit(LimiteOperacion.Limit)
                :
                setActualMenuLimit(LimiteOperacion.MARKET)
        }
    };

    const handleCloseMenuOperaciones = (op?: string) => {
        setAnchorEl(null);
        if (op) {
            op === TipoOperacion.Compra ?
                setActualOption(TipoOperacion.Compra)
                :
                setActualOption(TipoOperacion.Venta)
        }
    };

    const handleCloseMenuTipoDolar = (op?: string) => {
        setAnchorEl(null);
        if (op && seteadorDolar) {
            op === TipoReinversionDolar.Local ?
                seteadorDolar("local")
                :
                seteadorDolar("exterior")
            openModalDola && openModalDola()
        }
    };

    return {
        setActualMenuLimit,
        listaMenuLimit,
        handleOpenMenu,
        handleCloseMenuLimit,
        open,
        anchorEl,
        listaMenuOperaciones,
        handleCloseMenuOperaciones,
        listaTipoPedidoDolar7000,
        handleCloseMenuTipoDolar,
    }
}

