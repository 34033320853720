import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { TipoAccount, TipoMercado, TipoSwitcher } from "../../../enums/enums";
import { useSocketConnection } from "../../../hooks/useSocketHook";
import { SwitchMercado } from "../../SwitchMercado/SwitchMercado";
import { Container, ContainerAltaCbu, ContainerSecundario, ContainerSwitcher, ContainerTitulo, Img, TextoAltaCbu, TitleText } from "./elements";
import { NotificacionContext } from '../../../context/notificacionContext/NotificacionContext';
import { ThemeContext } from 'styled-components';
import SwitchTheme from '../../SwitchTheme/SwitchTheme';
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { AuthContext } from '../../../context/authContext/AuthContext';
import AutocompleteEspecies from '../../MovimientosComponents/AutocompleteEspecies/AutocompleteEspecies';
import { getClientesCuentas } from '../../../api/userApi';
import { BotonTitulos, TextoBotonTitulos } from '../../../views/Movimientos/elements';

type Props = {
    titulo?: string;
    showSwitch: boolean;
    esDetalleEspecie?: boolean;
    esMiBilletera?: boolean;
}

export const HeaderDesk = ({ titulo, showSwitch, esDetalleEspecie, esMiBilletera }: Props) => {

    const navigate = useNavigate();

    const { logoOn } = useContext(NotificacionContext);

    const { primaryColor, rojoPorcentaje } = useContext(ThemeContext);

    const { tipoMercado } = useContext(MercadoContext);

    const { accountType, cuentasClientes, setAccountSearched, setCuentasClientes, accountSearched } = useContext(AuthContext);

    const accountTypeStorage = localStorage.getItem("accountType");

    useSocketConnection();

    const styles = {
        notification: {
            color: primaryColor,
            padding: "0.25em"
        },
        user: {
            color: primaryColor,
            padding: "0.25em",
        },
    };

    const banderas = {
        local: require('../../../images/bandera-argentina-cuenta.png'),
        exterior: require('../../../images/bandera-usa.png'),
    }

    useEffect(() => {
        const refresh = (async () => {
            const tokenStorage = localStorage.getItem("token")
            try {
                if (tokenStorage && !cuentasClientes && (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin)) {
                    const respCuentasClientes = await getClientesCuentas(tokenStorage)
                    setCuentasClientes(respCuentasClientes.data.body)
                }
            } catch (error) {
                console.log(error);
            }
        })()
    }, [])


    return (
        <Container>
            <ContainerTitulo>
                {
                    titulo ?
                        <TitleText>{titulo}</TitleText>
                        : null
                }
            </ContainerTitulo>

            <ContainerSecundario>
                {
                    (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) && cuentasClientes
                        ? <AutocompleteEspecies
                            data={cuentasClientes?.map(c => `${c.codigoComitente} - ${c.nombre}`)}
                            setDataSeleccionada={setAccountSearched}
                            title="Cuentas"
                            dataSeleccionada={accountSearched}
                        />
                        : null
                }

                {
                    titulo === 'Movimientos de cuenta corriente' &&
                    <BotonTitulos
                        onClick={() => navigate('/perfil/movimientos/titulos')}
                    >
                        <TextoBotonTitulos>MOVIMIENTOS DE TÍTULOS</TextoBotonTitulos>
                    </BotonTitulos>
                }


                {showSwitch
                    ? <ContainerSwitcher>
                        <SwitchMercado type={TipoSwitcher.Home} />
                    </ContainerSwitcher>
                    : null
                }

                {
                    esDetalleEspecie &&
                    <Img
                        src={tipoMercado === TipoMercado.Local ? banderas.local : banderas.exterior}
                        alt='bandera'
                    />
                }

                {
                    esMiBilletera &&
                    <ContainerAltaCbu
                        onClick={() => {
                            navigate("/agregar-cuenta")
                        }}
                    >
                        <AccountBalanceOutlinedIcon
                            style={{
                                margin: 0,
                                color: primaryColor,
                                padding: '0.2em',
                            }}
                        />
                        <TextoAltaCbu>SOLICITAR EL ALTA DE CBU</TextoAltaCbu>
                    </ContainerAltaCbu>
                }

                <SwitchTheme />

                <IconButton
                    onClick={() => {
                        navigate("/notificaciones")
                    }}
                    aria-label='notificaciones'
                    sx={styles.notification}
                >
                    {logoOn ?
                        <NotificationsActiveOutlinedIcon
                            style={{ color: rojoPorcentaje }}
                        />
                        :
                        <NotificationsNoneIcon />
                    }
                </IconButton>
                <IconButton
                    onClick={() => {
                        navigate("/perfil")
                    }}
                    aria-label='perfil'
                    sx={styles.user}
                >
                    <PersonOutlineIcon ></PersonOutlineIcon>
                </IconButton>
            </ContainerSecundario>

        </Container>
    )
}


