import { Autocomplete, autocompleteClasses, createFilterOptions, Popper, styled, TextField, Typography, useAutocomplete } from '@mui/material';
import React, { useContext } from 'react'
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { ThemeContext } from 'styled-components';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { UserContext } from '../../../context/userContext/UserContext';



function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number),
    };

    const { key, ...optionProps } = dataSet[0];

    return (
        <Typography
            key={key}
            component="li"
            {...optionProps}
            // style={inlineStyle}
            style={{
                ...inlineStyle,
                padding: '8px', // Espacio extra para hacer el diseño más estético
                wordWrap: 'break-word', // Permite el salto de línea en palabras largas
                whiteSpace: 'normal' // Permite que el texto largo ocupe varias líneas
            }}
        >
            {dataSet[1]}
        </Typography>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];
    (children as React.ReactElement[]).forEach(
        (item: React.ReactElement & { children?: React.ReactElement[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        },
    );

    const itemCount = itemData.length;

    const gridRef = useResetCache(itemCount);
    // const itemSize = 35

    // const getChildSize = (child: React.ReactElement) => {
    //     return itemSize;
    // };

    // const getHeight = () => {
    //     if (itemCount > 8) {
    //         return 8 * itemSize;
    //     }
    //     return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    // };
    const getChildSize = (child: any) => {
        const textLength = child[1].length;
        const lineHeight = 24; // Define la altura de línea base
        const padding = 16; // Espacio de padding vertical (8px arriba y abajo)

        // Calcula la altura en función de la longitud de texto, asumiendo 30 caracteres por línea
        const height = Math.ceil(textLength / 15) * lineHeight + padding;
        return height < 35 ? 35 : height; // Mínimo de 35px para elementos cortos
    };

    const getHeight = () => {
        const maxItemsToShow = 8;
        const visibleItemsHeight = itemData.slice(0, maxItemsToShow).map(getChildSize);
        return visibleItemsHeight.reduce((a, b) => a + b, 0);
    };


    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight()}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={10}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});



interface Props {
    data: string[];
    setDataSeleccionada: React.Dispatch<React.SetStateAction<string>>;
    title: string;
    dataSeleccionada?: string;
}

const AutocompleteEspecies = ({ data, setDataSeleccionada, title, dataSeleccionada }: Props) => {

    const { primaryBackgroundColor, primaryColor } = useContext(ThemeContext);

    const { loadingTenencia } = useContext(UserContext)

    const filterOptions = createFilterOptions({
        matchFrom: title === "Especies" ? 'start' : 'any',
    });

    const StyledPopper = styled(Popper)({
        [`& .${autocompleteClasses.listbox}`]: {
            backgroundColor: primaryBackgroundColor,
            boxSizing: 'border-box',

            '& ul': {
                padding: 0,
                margin: 0,
                backgroundColor: primaryBackgroundColor,
                color: primaryColor
            },
        },
    });

    const { width } = useWindowDimensions()

    return (
        <Autocomplete
            id="virtualize-demo"
            disabled={loadingTenencia}
            sx={{
                width: width > 1200 ? '9em' : '7em',
                "& .MuiOutlinedInput-root": {
                    color: primaryColor,
                    '& fieldset': {
                        borderColor: primaryColor, // Border color when not focused
                    },
                    '&:hover fieldset': {
                        borderColor: primaryColor, // Border color on hover
                    },
                    '&.Mui-focused fieldset': {
                        border: `1px solid ${primaryColor}`
                    },
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: primaryColor
                },
                '& .MuiInputBase-input': {
                    color: primaryColor, // Text color
                    // padding: '0 !important',
                    margin: '0 !important',
                    // fontSize: '1em',
                },
                '& .MuiInputLabel-outlined': {
                    color: primaryColor,
                    '&.Mui-focused': {
                        color: primaryColor,
                    }
                },
            }}
            value={dataSeleccionada || ""}
            disableListWrap
            freeSolo
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            size='small'
            disableClearable
            options={data}
            filterOptions={filterOptions}
            popupIcon={
                <KeyboardArrowRightRoundedIcon
                    sx={{
                        color: primaryColor
                    }}
                />
            }
            renderInput={
                (params) =>
                    <TextField
                        {...params}
                        label={title}
                        onChange={(e) => {
                            title === "Especie" && setDataSeleccionada(e.target.value);
                        }}
                    />}
            renderOption={(props, option, state) =>
                [props, option, state.index] as React.ReactNode
            }
            onChange={(event, value) => {
                setDataSeleccionada(value as string)
                title === "Cuentas" && localStorage.setItem("accountSearched", value as string)
            }}
        />
    )
}

export default AutocompleteEspecies