import React, { useContext, useEffect, useRef, useState } from 'react'
import { DataEspecie, DataEspecieOpciones } from '../interfaces/mercadoInferfaces';
import { TipoMercado, TipoNavigate } from '../enums/enums';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { EspeciesFavoritasContext } from '../context/especiesFavoritasContext/EspeciesFavoritasContext';
import { AuthContext } from '../context/authContext/AuthContext';
import { getDataEspecie, getDataPanel, getDataPanelOpciones } from '../api/mercadoApi';
import { getFavourites } from '../api/userApi';
import { arrMenuListMercadoInternacional, arrMenuListMercadoLocal } from "../helpers/arrayEspecies";
import moment from 'moment';
import { NavigationContext } from '../context/navigationContext/NavigationContext';

const useEspecieHookListaEspecie = (actualMenuEspecies: string) => {

    const [favoritos, setFavoritos] = useState<DataEspecie[] | null | undefined>([]);
    const [filtrado, setFiltrado] = useState<DataEspecie[] | null | undefined>(favoritos);
    const [loadingEspecies, setLoadingEspecies] = useState(false);
    const [dataOpciones, setDataOpciones] = useState<DataEspecieOpciones[] | null | undefined>(null);
    const { tipoMercado, reloaded, setReloaded } = useContext(MercadoContext);
    const { setEspeciesFromApi, especiesExteriorFavoritas, especiesLocalesFavoritas } = useContext(EspeciesFavoritasContext);
    const { tokenPublico, setToken, setTokenPublico } = useContext(AuthContext);
    const [dataListaEspecies, setDataListaEspecies] = useState<DataEspecie[] | null>(null);
    // const [noHayData, setNoHayData] = useState<boolean>(false);
    // const [dataOpcionesFiltrado, setDataOpcionesFiltrado] = useState<DataEspecieOpciones[] | null | undefined>(null);
    const { setSelectedNavigate } = useContext(NavigationContext);



    let ultimaHora = useRef('0');


    function esMercadoLocal() {
        return tipoMercado === TipoMercado.Local;
    }

    function hayFavoritos() {
        return esMercadoLocal()
            ? especiesLocalesFavoritas.length !== 0
            : especiesExteriorFavoritas.length !== 0;
    }

    
    // const asegurarseQueNoHayDatos = (data: any) => {
    //     if (data === null || data.length === 0) {
    //         setNoHayData(true);
    //     }
    // }

    // useEffect(() => {
    //     if (esMercadoLocal()) {
    //         let favoritoEliminado = favoritos?.filter(
    //             favs => !especiesLocalesFavoritas.includes(favs.especie),
    //         );
    //         if (favoritoEliminado && favoritoEliminado[0] !== undefined) {
    //             let idx = favoritos?.findIndex(
    //                 fav => fav.especie === favoritoEliminado![0].especie,
    //             );
    //             if (typeof idx === 'number') {
    //                 setFavoritos(favoritos =>
    //                     favoritos?.filter((_, index) => index !== idx),
    //                 );
    //             }
    //         }
    //     } else {
    //         let favoritoEliminado = favoritos?.filter(
    //             favs => !especiesExteriorFavoritas.includes(favs.especie),
    //         );
    //         if (favoritoEliminado && favoritoEliminado[0] !== undefined) {
    //             let idx = favoritos?.findIndex(
    //                 fav => fav.especie === favoritoEliminado![0].especie,
    //             );
    //             if (typeof idx === 'number') {
    //                 setFavoritos(favoritos =>
    //                     favoritos?.filter((_, index) => index !== idx),
    //                 );
    //             }
    //         }
    //     }
    // }, [especiesLocalesFavoritas, especiesExteriorFavoritas]);

    useEffect(() => {
        const refresh = async () => {
            try {
                setReloaded(true)
                setSelectedNavigate(TipoNavigate.Mercado)
                setLoadingEspecies(true);
                const tokenStorage = localStorage.getItem("token");
                const tokenPublicoStorage = localStorage.getItem("tokenPublico");
                tokenStorage && setToken(tokenStorage);
                tokenPublicoStorage && setTokenPublico(tokenPublicoStorage);
                if (tokenPublicoStorage && tokenStorage) {
                    const resp = await getFavourites(tokenStorage);
                    let arrLocal: string[] = [];
                    let arrExterior: string[] = [];
                    resp.data.body.favouriteList.forEach(element => {
                        if (element.tipoMercado === 'LOCAL') {
                            arrLocal.push(element.especie);
                        } else {
                            arrExterior.push(element.especie);
                        }
                    });
                    setEspeciesFromApi(resp.data.body.favouriteList);
                    if (tipoMercado === TipoMercado.Local) {
                        if (arrLocal.length !== 0) {
                            const respFavsLocal = await getDataEspecie(tokenPublicoStorage, arrLocal);
                            setFavoritos(respFavsLocal.data.body);
                            setFiltrado(respFavsLocal.data.body);
                        }
                    } else {
                        if (arrExterior.length !== 0) {
                            const respFavsExt = await getDataEspecie(tokenPublicoStorage, arrExterior);
                            setFavoritos(respFavsExt.data.body);
                            setFiltrado(respFavsExt.data.body);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingEspecies(false);
            }
        }
        if (!reloaded) {
            refresh();
        }
    }, [filtrado])



    useEffect(() => {
        setearFiltradoData();
    }, [actualMenuEspecies, dataListaEspecies, favoritos]);

    useEffect(() => {
        setDataListaEspecies([]);
        obtenerData();
    }, [actualMenuEspecies, tipoMercado]);

    function getHour() {
        return moment().format('HH:mm:ss');
    }

    function searchPanelParam(panel: string) {
        const found =
            tipoMercado === TipoMercado.Local
                ? arrMenuListMercadoLocal.filter(e => e.title === panel)
                : arrMenuListMercadoInternacional.filter(e => e.title === panel);
        return found[0].panel;
    }

    async function obtenerData() {
        setLoadingEspecies(true)
        // setNoHayData(false)
        ultimaHora.current = getHour();
        if (tokenPublico) {
            if (actualMenuEspecies === 'FAVORITOS') {
                if (esMercadoLocal()) {
                    if (especiesLocalesFavoritas.length !== 0) {
                        try {
                            let resp = await getDataEspecie(
                                tokenPublico,
                                especiesLocalesFavoritas,
                            );
                            // asegurarseQueNoHayDatos(resp.data.body)
                            setFavoritos(resp.data.body);
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setLoadingEspecies(false)
                        }
                    }
                } else {
                    if (especiesExteriorFavoritas.length !== 0) {
                        try {
                            let resp = await getDataEspecie(
                                tokenPublico,
                                especiesExteriorFavoritas,
                            );
                            // asegurarseQueNoHayDatos(resp.data.body)
                            setFavoritos(resp.data.body);
                        } catch (error) {
                            console.log(error);
                        } finally {
                            setLoadingEspecies(false);
                        }
                    } 
                }
            } else {
                try {
                    const param = searchPanelParam(actualMenuEspecies);
                    let resp;
                    if (param !== 'OPC') {
                        resp = await getDataPanel(tokenPublico, param!, tipoMercado);
                        // asegurarseQueNoHayDatos(resp.data.body)
                        setDataListaEspecies(resp.data.body);
                    } else {
                        resp = await getDataPanelOpciones(tokenPublico);
                        // asegurarseQueNoHayDatos(resp.data.body)
                        setDataOpciones(resp.data.body);
                        // setDataOpcionesFiltrado(resp.data.body);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoadingEspecies(false);
                }
            }
        }
        setLoadingEspecies(false)
    }


    function setearFiltradoData() {
            if (actualMenuEspecies === 'FAVORITOS') {
                setFiltrado(favoritos);
            }else {
                setFiltrado(dataListaEspecies);
            }
    }
    // const filtradoSearhBar = (especie: string) => {
    //         if (especie) {
    //             if (actualMenuEspecies === 'OPCIONES') {
    //                 setDataOpcionesFiltrado(dataOpciones?.filter(d => d.especie.toLocaleLowerCase().startsWith(especie.toLocaleLowerCase())))
    //             } else {
    //                 setFiltrado(dataListaEspecies?.filter(d => d.especie.toLocaleLowerCase().startsWith(especie.toLocaleLowerCase())));
    //             }
    //         } else if (actualMenuEspecies !== 'OPCIONES') {
    //             setFiltrado(dataListaEspecies);
    //         } else {
    //             setDataOpcionesFiltrado(dataOpciones);
    //         }
    // }

  return {
    hayFavoritos, 
    filtrado, 
    dataOpciones, 
    loadingEspecies,
    favoritos,
  }
}

export default useEspecieHookListaEspecie


