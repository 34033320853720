import React, { useContext, useEffect, useState } from 'react'
import { ButtonsPlazo, Container, ContainerButtonsPlazo, ContainerDiaria, ContainerSaldoDisponible, ContainerTenencia, ContainerTextoInput, Input, TenenciaTotalText, Texto, TextoDiaria } from './elements'
import SearchOperar from '../../operarComponents/SearchOperar/SearchOperar'
import BotonesComprarVenderNuevo from '../BotonesComprarVenderNuevo/BotonesComprarVenderNuevo'
import { LimiteOperacion, TipoMercado, TipoOperacion } from '../../../enums/enums'
import { ThemeContext } from 'styled-components'
import { separacionDecimal } from '../../../helpers/separacionDecimal'
import { UserContext } from '../../../context/userContext/UserContext'
import { formatCurrency } from '../../../helpers/formatCurrency'
import { DatosClaveEspecie, Panel } from '../../../interfaces/nombreEspeciesInterfaces'

interface Props {
    tipoMercado: TipoMercado;
    actualMenuLimit: LimiteOperacion;
    setActualMenuLimit: React.Dispatch<React.SetStateAction<LimiteOperacion>>;
    onChangeCantidad: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
    onChangePrecio: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
    onChangeImporte: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
    precioFormateado: string;
    importeFormateado: string;
    cantidadFormateada: string;
    setActualOption: React.Dispatch<React.SetStateAction<TipoOperacion | null>>
    continuar: (tipoOperacion: TipoOperacion) => Promise<void>
    tenenciaTotal: number;
    esDetalleEspecie?: boolean;
    choiced: DatosClaveEspecie | null
    textoTitulos: string
}

const IngresoDatosOperar = ({
    tipoMercado,
    actualMenuLimit,
    setActualMenuLimit,
    onChangeCantidad,
    onChangePrecio,
    onChangeImporte,
    precioFormateado,
    importeFormateado,
    cantidadFormateada,
    setActualOption,
    continuar,
    tenenciaTotal,
    esDetalleEspecie,
    choiced,
    textoTitulos
}: Props) => {

    const { primaryBackgroundColor, primaryColor } = useContext(ThemeContext)
    const { tenencia, loadingTenencia } = useContext(UserContext)

    const [fontSizeTipoAlerta, setFontSizeTipoAlerta] = useState("16px"); // Tamaño predeterminado

    const [saldoDisponible, setSaldoDisponible] = useState<number | null | undefined>(null)
    const [saldoCi, setSaldoCi] = useState<number | null | undefined>(null)
    const [saldo24, setSaldo24] = useState<number | null | undefined>(null)

    useEffect(() => {
        if (tenencia) {
            if (tipoMercado === TipoMercado.Exterior) {
                setSaldoDisponible(tenencia?.saldo.dolarExterior)
            } else {
                if (choiced?.panel === Panel.Bonos && choiced.especie_completa?.slice(-3) === "USD") {
                    setSaldoCi(tenencia?.saldo.saldoCIDolarBi)
                    setSaldo24(tenencia?.saldo.saldo24HsDolarBi)
                    setSaldoDisponible(tenencia?.saldo.dolarBillete)
                } else {
                    setSaldo24(parseFloat(tenencia?.saldo.saldo24HsPesos))
                    setSaldoCi(parseFloat(tenencia?.saldo.saldoCIPesos))
                    setSaldoDisponible(tenencia?.saldo.pesos)
                }
            }
        }
    }, [choiced, textoTitulos, tipoMercado, tenencia])


    useEffect(() => {
        const handleResize = () => {
            setFontSizeTipoAlerta(window.innerWidth < 1025 ? "11px" : "14px");
        };

        // Establecer tamaño inicial
        handleResize();

        // Escuchar cambios en el tamaño de la ventana
        window.addEventListener("resize", handleResize);

        // Limpiar el listener cuando el componente se desmonta
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Container>
            <ContainerTextoInput style={{ paddingTop: '0.5em' }}>
                <Texto>Especie</Texto>
                <SearchOperar />
            </ContainerTextoInput>

            {tipoMercado === TipoMercado.Local
                ? <ContainerTextoInput>
                    <Texto style={{ fontSize: fontSizeTipoAlerta }}>Tipo de orden</Texto>
                    <ContainerDiaria>
                        <TextoDiaria>{actualMenuLimit}</TextoDiaria>
                    </ContainerDiaria>
                </ContainerTextoInput>
                : <ContainerTextoInput>
                    <Texto>Tipo de orden</Texto>
                    <ContainerButtonsPlazo>
                        <ButtonsPlazo
                            style={{
                                backgroundColor: actualMenuLimit === LimiteOperacion.Limit ? primaryColor : primaryBackgroundColor,
                                color: actualMenuLimit === LimiteOperacion.Limit ? primaryBackgroundColor : primaryColor,
                            }}
                            onClick={() => setActualMenuLimit(LimiteOperacion.Limit)}
                        >Limit</ButtonsPlazo>
                        <ButtonsPlazo
                            style={{
                                backgroundColor: actualMenuLimit === LimiteOperacion.MARKET ? primaryColor : primaryBackgroundColor,
                                color: actualMenuLimit === LimiteOperacion.MARKET ? primaryBackgroundColor : primaryColor,
                            }}
                            onClick={() => setActualMenuLimit(LimiteOperacion.MARKET)}
                        >Market</ButtonsPlazo>
                    </ContainerButtonsPlazo>
                </ContainerTextoInput>
            }

            <ContainerTextoInput>
                <Texto>Cantidad</Texto>
                <ContainerDiaria>
                    <Input
                        placeholder='Cantidad'
                        onChange={onChangeCantidad}
                        value={cantidadFormateada}
                        type='text'
                    ></Input>
                </ContainerDiaria>
            </ContainerTextoInput>
            <ContainerTextoInput>
                <Texto>Precio</Texto>
                <ContainerDiaria>
                    {actualMenuLimit === LimiteOperacion.Limit
                        ?
                        <Input
                            placeholder='Precio'
                            onChange={onChangePrecio}
                            value={precioFormateado}
                            type='text'
                        ></Input>
                        : null
                    }
                </ContainerDiaria>
            </ContainerTextoInput>
            <ContainerTextoInput>
                <Texto>Importe</Texto>
                <ContainerDiaria>
                    {actualMenuLimit === LimiteOperacion.Limit
                        ?
                        <Input
                            placeholder='Importe'
                            type='text'
                            onChange={onChangeImporte}
                            value={importeFormateado}
                        ></Input>
                        : null
                    }
                </ContainerDiaria>
            </ContainerTextoInput>


            {
                loadingTenencia
                    ? null
                    : <>
                        <ContainerSaldoDisponible style={{ marginTop: "4px" }}>
                            <Texto style={{ fontSize: "small" }}>
                                Saldo disponible:
                                {tipoMercado === TipoMercado.Local
                                    ? ` $${formatCurrency(saldoDisponible)}`
                                    : ` USD ${formatCurrency(saldoDisponible)}`
                                }</Texto>
                        </ContainerSaldoDisponible>
                        {
                            tipoMercado === TipoMercado.Local && saldoCi != null && saldo24 != null ?
                                <div>
                                    <ContainerSaldoDisponible>
                                        <Texto style={{ fontSize: "small" }}>
                                            Vencido:
                                            {` $${formatCurrency(saldoCi)}`}</Texto>
                                    </ContainerSaldoDisponible>
                                    <ContainerSaldoDisponible>
                                        <Texto style={{ fontSize: "small", color: "red" }}>
                                            24h.:
                                            {` $${formatCurrency(saldo24)}`}</Texto>
                                    </ContainerSaldoDisponible>
                                </div> : null
                        }
                    </>
            }
            {esDetalleEspecie &&
                <ContainerTenencia>
                    <TenenciaTotalText>
                        Tenencia actual: {separacionDecimal(tenenciaTotal)}
                    </TenenciaTotalText>
                </ContainerTenencia>
            }


            <BotonesComprarVenderNuevo
                setActualOption={setActualOption}
                continuar={continuar}
            />
        </Container>
    )
}

export default IngresoDatosOperar