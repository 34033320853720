import { useContext } from "react";
import { CircularProgress } from "@mui/material"
import { CarteraCardHome } from "../../components/homeComponents/CarteraCardHome/CarteraCardHome";
import { HeaderBar } from "../../components/homeComponents/HeaderBar/HeaderBar";
import { UserCardHome } from "../../components/homeComponents/UserCardHome/UserCardHome";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { HeaderDesk } from "../../components/HeadersComponents/HeaderDesk/HeaderDesk";
import { Container, ContenedorPrincipal, HeaderContainer, OperarContainer, PhoneContainer, RowContainer } from "./elements";
import { ThemeContext } from "styled-components";
import { LoadingContainer } from "../../elements/loadingContainer";
import useTenenciasHook from "../../hooks/useTenenciasHook";
import OperarNuevo from "../OperarNuevo/OperarNuevo";
import useCargarInfoHomeHook from "../../hooks/useCargarInfoHomeHook";


export const Home = () => {

    const { width } = useWindowDimensions()
    const { tenenciasSeparadas, loadingTenenciasSeparadas, errorTenencia } = useTenenciasHook();
    const { loadingColor } = useContext(ThemeContext);
    const { loadingData, errorDatosPersonales } = useCargarInfoHomeHook();

    return (
        <Container>
            {
                width > 992 ?
                    <HeaderDesk showSwitch={true} />
                    :
                    <HeaderContainer>
                        <HeaderBar titulo="" />
                    </HeaderContainer>
            }

            {
                loadingData ?
                    <LoadingContainer>
                        <CircularProgress style={{ color: loadingColor }} />
                    </LoadingContainer>
                    :
                    <>
                        {
                            width > 992 ?
                                <ContenedorPrincipal>
                                    <UserCardHome
                                        errorDatosPersonales={errorDatosPersonales}
                                    />
                                    <RowContainer>
                                        {loadingTenenciasSeparadas ?
                                            <LoadingContainer style={{ width: "100%"}}>
                                                <CircularProgress style={{ color: loadingColor }} />
                                            </LoadingContainer>
                                            :
                                            <CarteraCardHome
                                                errorTenencia={errorTenencia}
                                                tenenciasSeparadas={tenenciasSeparadas}
                                                loadingTenencia={loadingTenenciasSeparadas}
                                            />}
                                        <OperarContainer>
                                            <OperarNuevo />
                                        </OperarContainer>
                                    </RowContainer>
                                </ContenedorPrincipal>
                                :
                                <PhoneContainer>
                                    <UserCardHome />
                                    <CarteraCardHome
                                        errorTenencia={errorTenencia}
                                        tenenciasSeparadas={tenenciasSeparadas}
                                        loadingTenencia={loadingTenenciasSeparadas}
                                    />
                                </PhoneContainer>
                        }
                    </>
            }
        </Container>
    )
};
