import apiInstance from '../utils/AxiosApiRava';
import {
  RespDataEspecie,
  RespDataEspecieOpciones,
  RespDataInterdiarios,
  RespDataOfertas,
} from '../interfaces/mercadoInferfaces';
import { dataToApi } from '../helpers/dataToApi';
import { RespEspecieReducida } from '../interfaces/dataCardsHome';
import { dates } from '../helpers/dateHelper';
import { RespCotizacionesEspecie } from '../interfaces/cotizacionesEspecies';
import { RespNombreEspecies } from '../interfaces/nombreEspeciesInterfaces';
import { AxiosRequestConfig, CancelTokenSource, InternalAxiosRequestConfig } from 'axios';
import { TipoMercado } from '../enums/enums';
import { RespEspeciePrecios } from '../interfaces/interfaceEspeciePrecio';
import { RespEspecieOpciones } from '../interfaces/especieOpcionesInterface';
import apiBackInstance from '../utils/AxiosApiBack';
import { RespHoraBolsa } from '../interfaces/respHoraBolsaInterface';
import { RespDataEspeciesRead } from '../interfaces/especiesReadInterface';

export async function getDataPanel(
  access_token: string,
  panel: string,
  tipoMercado: TipoMercado,
  hora_desde?: string,
) {
  let data;
  if (hora_desde) {
    data = dataToApi({
      access_token,
      panel,
      plazo:
        tipoMercado === TipoMercado.Local && panel !== 'OPC' ? 2 : undefined,
      soloactivos: panel === 'OPC' ? 1 : undefined,
      nombres: 1,
      hora_desde,
      campos:
        'especie,ultimo,variacion,plazo,hora,cantcompra,preciocompra,precioventa,cantventa,volefectivo,varMTD,varYTD,simbolo,nombre',
    });
  } else {
    data = dataToApi({
      access_token,
      panel,
      plazo:
        tipoMercado === TipoMercado.Local && panel !== 'OPC' ? 2 : undefined,
      nombres: 1,
      soloactivos: panel === 'OPC' ? 1 : undefined,
      campos:
        'especie,ultimo,variacion,hora,plazo,cantcompra,preciocompra,precioventa,cantventa,volefectivo,varMTD,varYTD,simbolo,nombre',
    });
  }
  return await apiInstance.post<RespDataEspecie>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}
export async function getDataPanelOpciones(
  access_token: string,
  hora_desde?: string,
) {
  let data;

  data = dataToApi({
    access_token,
    panel: 'OPC',
    soloactivos: 1,
    nombres: 1,
    hora_desde,
    campos:
      'especie,ultimo,variacion,hora,plazo,cantcompra,preciocompra,precioventa,cantventa,volefectivo,varMTD,varYTD,simbolo,nombre,especieopciones',
  });

  return await apiInstance.post<RespDataEspecieOpciones>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}
//filtro en panel los opc con datos vacios
// if (panel === 'OPC') {
//   const arrFilter = resp.data.body.filter(
//     especie =>
//       especie.preciocompra !== '' &&
//       especie.precioventa !== '' &&
//       especie.variacion !== 0 &&
//       especie.ultimo !== '',
//   );
//   resp.data.body = arrFilter;
// }

export async function getDataEspecie(
  access_token: string,
  especies: string[],
  // tipoMercado: TipoMercado,
  hora_desde?: string,
) {
  let data;
  if (hora_desde) {
    data = dataToApi({
      access_token,
      especies: especies.join(),
      nombres: 1,
      campos:
        'especie,ultimo,variacion,hora,plazo,cantcompra,preciocompra,precioventa,cantventa,volefectivo,varMTD,varYTD,simbolo,nombre',
      especiecorta: 1,
      hora_desde,
    });
  } else {
    data = dataToApi({
      access_token,
      especies: especies.join(),
      nombres: 1,
      campos:
        'especie,ultimo,variacion,hora,plazo,cantcompra,preciocompra,precioventa,cantventa,volefectivo,varMTD,varYTD,simbolo,nombre',
      especiecorta: 1,
    });
  }
  return await apiInstance.post<RespDataEspecie>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}

export async function getEspecieOpciones(access_token: string) {
  /* let access_token = await getToken(); */
  const data = dataToApi({
    access_token,
  });
  return await apiInstance.post<RespEspecieOpciones>(
    '/publico/cotizaciones/especieopciones',
    data,
  );
}

export async function getDataMercadoLocal(access_token: string) {
  /* let access_token = await getToken(); */
  const data = dataToApi({
    access_token,
    especies: 'RIESGO PAIS,MERVAL,MERVAL USD',
    nombres: '1',
    campos: 'especie,ultimo,variacion,nombre,simbolo',
    orden: 'listaespecies',
  });
  return await apiInstance.post<RespEspecieReducida>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}

export async function getDataMercadoUSA(access_token: string) {
  const data = dataToApi({
    access_token,
    especies: 'DIA_US,QQQ_US,SPY_US',
    nombres: '1',
    campos: 'especie,ultimo,variacion,nombre,simbolo',
    orden: 'listaespecies',
  });
  return await apiInstance.post<RespEspecieReducida>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}

export async function getDataDolar(access_token: string, reducido: boolean) {
  const data = dataToApi({
    access_token,
    especies: reducido
      ? 'DOLAR OFICIAL,DOLAR MEP,DOLAR CCL'
      : 'DOLAR OFICIAL,DOLAR MEP,DOLAR MEP GD30,DOLAR CCL,DOLAR CCL GD30,DOLAR CCL CEDEAR',
    nombres: '1',
    campos: 'especie,ultimo,variacion,nombre,simbolo',
    orden: 'listaespecies',
  });
  return await apiInstance.post<RespEspecieReducida>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}

export async function getDataTopCincoExt(access_token: string) {
  const data = dataToApi({
    access_token,
    panel: 'TOP5EXT',
    orden: 'variacion',
    tipoOrden: 'desc',
    campos: 'simbolo,ultimo,variacion,especie' 
  });
  return await apiInstance.post<RespEspecieReducida>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}

export async function getDataActivasCincoExt(access_token: string) {
  const data = dataToApi({
    access_token,
    panel: 'ACTIVAS5EXT',
    orden: 'variacion',
    tipoOrden: 'desc',
    campos: 'simbolo,ultimo,variacion,especie' 
  });
  return await apiInstance.post<RespEspecieReducida>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}

export async function getDataMEP(access_token: string) {
  const data = dataToApi({
    access_token,
    especies: 'DOLAR MEP',
    nombres: '1',
    campos: 'especie,ultimo,variacion,nombre,simbolo',
    orden: 'listaespecies',
  });
  return await apiInstance.post<RespEspecieReducida>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}

export async function getHistoricoEspecie(
  access_token: string,
  especie: string | undefined,
) {
  const { fecha_inicio, fecha_fin } = dates();

  const data = dataToApi({
    access_token,
    especie,
    fecha_inicio,
    fecha_fin,
    orden: 'asc',
  });
  return await apiInstance.post<RespCotizacionesEspecie>(
    '/publico/cotizaciones/historicos',
    data,
  );
}

//screen detalle componenete operaciones
//https://clasico.rava.com/lib/restapi/v3/publico/cotizaciones/intradiarios
export async function getIntradiarios(access_token: string, especie: string) {
  const data = dataToApi({
    access_token,
    especie: especie,
    limite: 10,
  });
  return await apiInstance.post<RespDataInterdiarios>(
    '/publico/cotizaciones/intradiarios',
    data,
  );
}

//screen detalle componente ofertas
//https://clasico.rava.com/lib/restapi/v3/publico/cotizaciones/ofertas

export async function getOfertas(access_token: string, especie: string) {
  const data = dataToApi({
    access_token,
    especie: especie,
  });
  return await apiInstance.post<RespDataOfertas>(
    '/publico/cotizaciones/ofertas',
    data,
  );
}


export async function getDetalleEspecies(
  access_token: string,
  cancelToken: CancelTokenSource,
  mercado: TipoMercado,
) {
  const data = dataToApi({
    access_token,
    mercado: mercado === TipoMercado.Local ? 'local' : 'exterior',
  });
  return await apiInstance.post<RespDataEspeciesRead>(
    '/publico/especies/read',
    data,
    {cancelToken: cancelToken.token},
  );
}

export async function getNombreEspecies(
  tipoMercado: TipoMercado
) {
  const reqString = tipoMercado === TipoMercado.Local ? "local" : "exterior"
  return await apiBackInstance.get<RespNombreEspecies>(`/especies/${reqString}`, {
  });
}


export async function getPrecioEspecieCorta(
  access_token: string,
  especies: string[],
  cancelToken: CancelTokenSource,
) {
  let data;

  data = dataToApi({
    access_token,
    especies: especies.join(),
    nombres: 1,
    especiecorta: 1,
    campos: 'ultimo,simbolo,especie',
  });

  return await apiInstance.post<RespEspeciePrecios>(
    '/publico/cotizaciones/ultimos',
    data,
    { cancelToken: cancelToken.token },
  );
}

function setToken(token: string) {
  apiBackInstance.interceptors.request.use(async function (
    config: AxiosRequestConfig<any>
  ): Promise<InternalAxiosRequestConfig<any>> {
    config.headers = {
      ...config.headers,
      authorization: token,
    };
    return config as InternalAxiosRequestConfig<any>;
  });
}

export async function getHorarioBolsa(token: string) {
  setToken(token);
  return await apiBackInstance.get<RespHoraBolsa>('horarioBolsa');
}

export async function getDataMercadoLocalCard(access_token: string) {
  let data;

  data = dataToApi({
    access_token,
    panel: 'LIDGEN',
    orden: 'variacion',
    tipoorden: 'desc',
    limite: 3,
    plazo: 2,
    campos: 'ultimo,simbolo,variacion,especie',
  });

  return await apiInstance.post<RespEspecieReducida>(
    '/publico/cotizaciones/ultimos',
    data,
  );
}