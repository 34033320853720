import { useContext } from 'react'
import List from '@mui/material/List/List';
import ListItem from '@mui/material/ListItem/ListItem';
import ListItemText from '@mui/material/ListItemText/ListItemText';
import { styled } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import { Icon } from '@iconify/react';
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material"
import { Container, DateContainer, DateText, EstadoContainer, EstadoText, FiltrosContainer, Input, InputContainer, ListContainer, SelectContainer, SinOrdenesContainer, SinOrdenesText, ErrorText, ContainerButtons, Botones, TextoBotones } from './elements';
import { useOrdenesHook } from '../../hooks/useOrdenesHook';
import { CardEspecieOrdenes } from '../../components/CardEspecieOrdenes/CardEspecieOrdenes';
import { ThemeContext } from 'styled-components';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';
import MenuPopUp from '../../components/MenuPopUp/MenuPopUp';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import BotonesPanelesNuevo from '../../components/mercadoNuevoComponents/BotonesPanelesNuevo/BotonesPanelesNuevo';

export const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 18,
    },
    '& .css-1cjpqna-MuiPaper-root-MuiMenu-paper-MuiPopover-paper': {
        backgroundColor: 'unset'
    }
}));


export const Ordenes = () => {

    const themeContext = useContext(ThemeContext)

    const { primaryColor, primaryBackgroundColor, loadingColor } = themeContext;

    const { width } = useWindowDimensions()

    const fechaActual = new Date();
    const anioActual = fechaActual.getFullYear();
    const mesActual = fechaActual.getMonth();
    const diaActual = fechaActual.getDate();

    const meses = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
    ];

    const fecha = `${diaActual} de ${meses[mesActual]}, ${anioActual}`;

    const esOrdenPermanente = (fecha: string) => {
        const dia = 0
        const mes = 1
        const anio = 2
        let fechaSeparada = fecha.split('/') 
        if (Number(fechaSeparada[dia]) > diaActual 
        && Number(fechaSeparada[mes]) >= mesActual 
        && fechaSeparada[anio] >= anioActual.toString().slice(-2)) {
            return fechaSeparada.join('/')
            
        }
        return ""
    }

    const {
        especiesFilt,
        formatEstado,
        listaMenuEstado,
        name,
        setName,
        handleOpenEstado,
        handleCloseEstado,
        openEstado,
        arrMenuList,
        loading,
        errorOrders,
        actualMenuEstado,
        setActualMenuEstado,
    } = useOrdenesHook();

    return (
        <Container>

            <ResponsiveHeader title='Órdenes' showSwitch={true} />

            <DateContainer>
                <DateText>{fecha}</DateText>
            </DateContainer>
            {width > 992 ?
                // <ContainerButtons>
                //     {arrMenuList.map(l =>
                //         <Botones key={l} onClick={() => setActualMenuEstado(l)}>
                //             <TextoBotones style={{
                //                 backgroundColor: actualMenuEstado === l ? primaryColor : primaryBackgroundColor,
                //                 color: actualMenuEstado === l ? primaryBackgroundColor : undefined,
                //             }}>{l}</TextoBotones>
                //         </Botones>
                //     )}
                // </ContainerButtons>
                <BotonesPanelesNuevo
                    setBotonActual={setActualMenuEstado}
                    botonActual={actualMenuEstado}
                    listaBotones={arrMenuList}
                />
                : null
            }

            <div style={{ margin: '0.5em 1em' }}>
                {width < 992
                    ? <EstadoContainer>
                        <EstadoText>Estado</EstadoText>
                    </EstadoContainer>
                    : null}
                <FiltrosContainer>
                    <InputContainer>
                        <Input
                            type="text"
                            placeholder='Especie'
                            defaultValue={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </InputContainer>
                    {width < 992
                        ? <SelectContainer>
                            <List component="nav"
                                style={{ ...styles.Menu, backgroundColor: primaryBackgroundColor, color: primaryColor, }}
                            >
                                <ListItem
                                    onClick={handleOpenEstado}
                                    style={styles.ListItem}
                                >
                                    <ListItemText primary={listaMenuEstado} />
                                    <Icon icon="material-symbols:arrow-forward-ios-rounded" style={{ width: '1em', height: '1em', marginRight: '0.8em' }} />
                                </ListItem>
                            </List>
                            <MenuPopUp
                                open={Boolean(openEstado)}
                                anchorEl={openEstado}
                                handleCloseMenu={handleCloseEstado}
                                listaMenu={arrMenuList}
                                actualMenu={null}
                            />
                        </SelectContainer>
                        : null}
                </FiltrosContainer>
            </div>
            {/* orders section */}
            {
                loading ?
                    <Box sx={{ display: "flex", justifyContent: "center", margin: "0.5em", alignItems: 'center', height: '80%', width: '95%' }}>
                        <CircularProgress style={{ color: loadingColor }} />
                    </Box>
                    :
                    <div>
                        {
                            errorOrders ?
                                <SinOrdenesContainer>
                                    <ErrorText>No podemos mostrar tus órdenes en este momento.
                                        Comunicate con tu asesor/a.</ErrorText>
                                </SinOrdenesContainer>
                                :
                                especiesFilt.length === 0 ?
                                    <SinOrdenesContainer>
                                        <SinOrdenesText>No hay órdenes</SinOrdenesText>
                                    </SinOrdenesContainer>
                                    :
                                    <div>
                                        <ListContainer>
                                            {
                                                especiesFilt.map(e =>
                                                    <CardEspecieOrdenes
                                                        key={e.nroOrden}
                                                        estado={formatEstado(e.estado)}
                                                        accion={e.tipoOrden}
                                                        nombre={e.especie}
                                                        hora={e.hora}
                                                        cantidad={e.cantidad}
                                                        precio={e.precio}
                                                        importe={e.importe}
                                                        nroOrden={e.nroOrden}
                                                        porcentaje={e.porcentajeEjercido}
                                                        ejercido={e.ejercido}
                                                        fecha={esOrdenPermanente(e.fecha)}
                                                    />
                                                )
                                            }
                                        </ListContainer>
                                    </div>
                        }
                    </div>
            }

        </Container>
    )
}


const styles = {
    Menu: {
        height: '2.2em',
        borderRadius: "4px",
        padding: '0',
        margin: '0.2em 0 0 0',
    },
    MenuEspecie: {
        height: '2.1em',
        borderRadius: "4px",
        padding: '0',
        margin: '0.5em 1em 1.5em 1em',
    },
    ListItem: {
        padding: '0.1em 0.5em 0.3em 0.5em',
        marginLeft: '0.1em',
        fontSize: '0.9em',
        cursor: 'pointer',
    },
    MenuItem: {
        width: '10.5em',
        fontSize: '0.9em'
    },
}