import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const NoFavsInfo = styled.p`
  color: ${({ theme }) => theme.accentColor};
`;

export const MostrarFavs = styled.p`
  color: ${({ theme }) => theme.accentColor};
  margin: 0;
`;

export const MostrarFavsAca = styled.p`
  color: ${({ theme }) => theme.primaryColor};
  margin: 0 0.4em;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.weightMostrarFavsMercado};
`;

export const ContainerSinInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;
