import React, { useContext } from 'react'
import { Container, ContainerSecundario, Titulo } from './elements'
import { MercadoContext } from '../../context/mercadoContext/MercadoContext'
import { OperarContext } from '../../context/operarContext/OperarContext';
import { useValidacionDatosOperar } from '../../hooks/useValidacionDatosOperar';
import IngresoDatosOperar from '../../components/OperarNuevoComponents/IngresoDatosOperar/IngresoDatosOperar';
import ConfirmarDatosOperar from '../../components/OperarNuevoComponents/ConfirmarDatosOperar/ConfirmarDatosOperar';
import AlertErrorDatosOperar from '../../alerts/AlertErrorDatosOperar';
import { TipoMercado } from '../../enums/enums';

interface Props {
    esDetalleEspecie?: boolean;
}

const OperarNuevo = ({ esDetalleEspecie }: Props) => {

    const { tipoMercado } = useContext(MercadoContext);

    const { error, continuar, setError } = useValidacionDatosOperar();

    const getSymbol = (): string => {
        return tipoMercado === TipoMercado.Exterior ? 'USD ' : '$';
    }

    const {
        setActualOption,
        onChangeCantidad,
        importe,
        onChangePrecio,
        cantidad,
        cantidadFormateada,
        precio,
        precioFormateado,
        onChangeImporte,
        importeFormateado,
        actualMenuLimit,
        setActualMenuLimit,
        deskOperar,
        actualOption,
        choiced,
        textoTitulos,
        tenenciaTotal,
    } = useContext(OperarContext);


    return (
        <Container>
            {/* <Titulo>Operar</Titulo> */}
            <ContainerSecundario style={{ height: esDetalleEspecie ? "16em" : undefined }}>
                <Titulo>Operar</Titulo>

                {!deskOperar
                    ? <IngresoDatosOperar
                        tipoMercado={tipoMercado}
                        actualMenuLimit={actualMenuLimit}
                        setActualMenuLimit={setActualMenuLimit}
                        onChangeCantidad={onChangeCantidad}
                        onChangePrecio={onChangePrecio}
                        onChangeImporte={onChangeImporte}
                        cantidadFormateada={cantidadFormateada}
                        precioFormateado={precioFormateado}
                        importeFormateado={importeFormateado}
                        setActualOption={setActualOption}
                        continuar={continuar}
                        tenenciaTotal={tenenciaTotal}
                        esDetalleEspecie={esDetalleEspecie}
                        choiced={choiced}
                        textoTitulos={textoTitulos}
                    />
                    : <ConfirmarDatosOperar
                        tipoOperacion={actualOption}
                        especie={choiced?.especie ? choiced.especie : textoTitulos}
                        tipoOrden={actualMenuLimit}
                        precio={precio}
                        cantidad={cantidad}
                        importe={importe}
                        getSymbol={getSymbol}
                    />
                }

                <AlertErrorDatosOperar
                    open={Boolean(error)}
                    texto={error}
                    handleClose={() => { setError('') }}
                />

            </ContainerSecundario>
        </Container>
    )
}

export default OperarNuevo