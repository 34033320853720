import { useContext } from 'react'
import { Container, ContainerTipoMoneda, InputField, SecondContainer, Titulo, Texto, ButtonConfirmar, ButtonCancelar, TextoSaldos } from './elements';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ThemeContext } from 'styled-components';
import { CustomRadioButtons } from '../../components/retirarDineroComponents/CustomRadioButtons/CustomRadioButtons';
import { RetirarDineroContext } from '../../context/retirarDineroContext/RetirarDineroContext';
import MenuPopUp from '../../components/MenuPopUp/MenuPopUp';
import useMenuRetirarDineroHook from '../../hooks/useMenuRetirarDineroHook';
import { useNavigate } from 'react-router-dom';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';
import { UserContext } from '../../context/userContext/UserContext';
import { formatCurrency } from '../../helpers/formatCurrency';
import { LoadingContainer } from '../../elements/loadingContainer';
import { CircularProgress } from '@mui/material';

export const RetirarDinero = () => {

    const { primaryColor, loadingColor } = useContext(ThemeContext);

    const { handleChangeImporte, disabled, moneda, importeRetirarFormateado } = useContext(RetirarDineroContext);
    const { tenencia } = useContext(UserContext);

    const {
        open,  
        listaTipoMoneda,
        handleOpenMenu,
        handleCloseMenuRetirarDinero,
        anchorEl,
        cuentasFiltradas,
        loadingCuentas
    } = useMenuRetirarDineroHook();

    const navigate = useNavigate();

    return (
        <Container>
            <ResponsiveHeader title='Retirar dinero' showSwitch={false} />
            {loadingCuentas
                ? <LoadingContainer style={{ height: '100%' }}>
                    <CircularProgress style={{ color: loadingColor }} />
                </LoadingContainer>
                :
                <div style={{ paddingTop: '0.1em' }}>
                    <SecondContainer>
                        <div>
                            <Titulo>Solicitar retirar dinero de la cuenta</Titulo>
                        </div>
                        {/* container para seleccionar el tipo de moneda */}
                        <ContainerTipoMoneda onClick={handleOpenMenu}>
                            <Texto>
                                {
                                    moneda.length ?
                                        `Solicitar transferir en ${moneda}`
                                        :
                                        'Seleccione el tipo de moneda'
                                }
                            </Texto>
                            <KeyboardArrowRightIcon
                                sx={{
                                    color: primaryColor,
                                    marginRight: '0.4em',
                                    marginTop: '0.2em',
                                    cursor: 'pointer',
                                    fontSize: '32px',
                                }} />
                        </ContainerTipoMoneda>
                        <MenuPopUp
                            open={open}
                            anchorEl={anchorEl}
                            handleCloseMenu={handleCloseMenuRetirarDinero}
                            listaMenu={listaTipoMoneda}
                            actualMenu={moneda}
                        />
                        <div>
                            <InputField
                                placeholder='Agregar importe'
                                type='text'
                                onChange={handleChangeImporte}
                                value={importeRetirarFormateado}
                            />
                        </div>
                    </SecondContainer>

                    <div style={{ margin: "1em", marginBottom: 0 }}>
                        <TextoSaldos>
                            {
                                moneda === "PESOS" ? (
                                    <>
                                        Saldo disponible: $<strong>{formatCurrency(tenencia?.saldo.pesos)}</strong>
                                    </>
                                ) : moneda === "" ? "" : (
                                    <>
                                        Saldo disponible: USD <strong>{formatCurrency(tenencia?.saldo.dolarBillete)}</strong>
                                    </>
                                )
                            }
                        </TextoSaldos>
                    </div>


                    {
                        moneda === "PESOS" ?
                            <div style={{ marginLeft: "1em" }}>
                                <TextoSaldos>
                                    {

                                        "Vencido: $" + formatCurrency(tenencia?.saldo.pesos)

                                    }
                                </TextoSaldos>
                            </div>

                            : null
                    }

                    <CustomRadioButtons cuentas={cuentasFiltradas} />
                    {
                        disabled ?
                            <ButtonCancelar>CONTINUAR</ButtonCancelar>
                            :
                            <ButtonConfirmar onClick={() => navigate('/confirmar-retirar-dinero')}>CONTINUAR</ButtonConfirmar>
                    }
                </div>
            }
        </Container>
    )
}
