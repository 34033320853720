import apiBackInstance from "../utils/AxiosApiBack";
import { RespDatosPersonales } from "../interfaces/datosPersonalesInterface";
import { RespOrders } from "../interfaces/ordersInterface";
import { RespTenencia } from "../interfaces/tenenciaInterface";
import { RespFavourites } from "../interfaces/favouritesInterface";
import { AlertaResp } from "../interfaces/alertaInterface";
import { RespValidateTouch } from "../interfaces/validateTouchInterface";
import { RespCreateOrden } from "../interfaces/createOrdenInterface";
import { RespSellOrder } from "../interfaces/sellOrderInterface";
import { CancelOrderResp } from "../interfaces/cancelOrderInterface";
import { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import { RespPregFrecuentes } from "../interfaces/pregFrecuentesInterface";
import { RespMovimientos } from "../interfaces/MovimientosInterface";
import { RespGetCuentas } from "../interfaces/getCuentasInterface";
import { RetirarDolares } from "../interfaces/retirarDolaresInterface";
import { TenenciaValorizada } from "../interfaces/tenenciaValorizadaInterface";
import { SignInIntreface } from "../interfaces/signInInterface";
import { CuentasClientes } from "../interfaces/cuentasClientesInterface";

function setToken(token: string) {
	apiBackInstance.interceptors.request.use(async function (
		config: AxiosRequestConfig<any>
	): Promise<InternalAxiosRequestConfig<any>> {
		config.headers = {
			...config.headers,
			authorization: token,
		};
		return config as InternalAxiosRequestConfig<any>;
	});
}

export async function signUp(
	emailUser: string,
	accountNumber: number,
	DNI: string
) {
	return await apiBackInstance.post("auth/signup", {
		emailUser,
		accountNumber,
		DNI,
	});
}

export async function signInApi(
	accountNumber: number,
	pin: string,
	DNI: string
) {
	return await apiBackInstance.post<SignInIntreface>("auth/signin", {
		accountNumber,
		pin,
		DNI,
	});
}

export async function getClientesCuentas(token: string) {
	setToken(token);
	return await apiBackInstance.get<CuentasClientes>("auth/clientes/list")
}

// export async function validateTouch(token: string) {
// 	setToken(token);
// 	return await apiBackInstance.post<RespValidateTouch>("auth/validateTouch");
// }

export async function changePin(pinOne: string, pinTwo: string, token: string) {
	setToken(token);
	return await apiBackInstance.post("auth/updatePin", {
		pinOne,
		pinTwo,
	});
}

export async function resetPin(
	emailUser: string,
	accountNumber: number,
	dni: string
) {
	return await apiBackInstance.put("auth/resetpassword", {
		emailUser,
		accountNumber,
		dni,
	});
}

export async function getUserData(token: string, accountSearched?: string) {
	setToken(token);
	let params = {
		accountSearched
	}
	return await apiBackInstance.get<RespDatosPersonales>("datosPersonales", { params });
}
// export async function getFavoritos(token:string) {
//   return await apiBackInstance.get<>('favourites');
// }
//crear interface
export async function getOrders(token: string, accountSearched?: string) {
	setToken(token);
	let params = {
		accountSearched
	}
	return await apiBackInstance.get<RespOrders>("orders", { params });
}

function formatName(name: string) {
	const searchTerm = "_";
	const indexOf = name.indexOf(searchTerm);
	return indexOf > 0 ? name.slice(0, indexOf).toLocaleUpperCase() : name;
}

export async function createOrder(
	token: string,
	comi: string,
	especie: string,
	ticker: string,
	cantidad: number,
	precio: number,
	accountSearched?: string,
	// vencimiento: string,
	// plazo : Plazo
) {
	setToken(token);
	ticker = formatName(ticker);
	console.log(accountSearched)
	return await apiBackInstance.post<RespCreateOrden>("orden", {
		comi,
		especie,
		ticker,
		cantidad,
		precio,
		accountSearched,
	});
}

export async function sellOrder(
	token: string,
	comi: string,
	especie: string,
	ticker: string,
	cantidad: number,
	precio: number,
	accountSearched?: string,
	// vencimiento: string,
	// plazo : Plazo
) {
	setToken(token);

	return await apiBackInstance.post<RespSellOrder>("orden/vender", {
		comi,
		especie,
		ticker,
		cantidad,
		precio,
		accountSearched,
	}); {
	}
}
export async function getTenencia(token: string, accountSearched?: string) {
	setToken(token);
	let params = {
		accountSearched
	}
	return await apiBackInstance.get<RespTenencia>("tenencia", { params });
}
// export async function getCartera(token: string) {
// 	setToken(token);
// 	return await apiBackInstance.get<RespCartera>("cartera");
// }
export async function deletOrders(
	token: string,
	especie: string,
	nroOrden: string,
	ticker: string,
	accountSearched?: string,
) {
	setToken(token);
	return await apiBackInstance.put<CancelOrderResp>("order", {
		especie,
		nroOrden,
		ticker,
		accountSearched,
	});
}

export async function getFavourites(token: string) {
	setToken(token);
	return await apiBackInstance.get<RespFavourites>("favourites");
}

export async function addFavourite(
	especie: string,
	tipoMercado: string,
	token: string
) {
	setToken(token);
	return await apiBackInstance.post("addFavourite", { especie, tipoMercado });
}

export async function deleteFavourite(especie: string, token: string) {
	setToken(token);
	return await apiBackInstance.put("deleteFavourite", { especie });
}

export async function getAlerts(token: string) {
	setToken(token);
	return await apiBackInstance.get<AlertaResp>("alerts");
}

export async function addAlert(
	especie: string,
	precio: number,
	tipoAlerta: string,
	tipoMercado: string,
	panel: string,
	opciones: string,
	token: string
) {
	setToken(token);
	return await apiBackInstance.post<AlertaResp>("addAlert", {
		especie,
		precio,
		tipoAlerta,
		tipoMercado,
		opciones,
		panel,
	});
}

export async function editAlert(
	alertId: string,
	precio: number,
	tipoAlerta: string,
	token: string
) {
	setToken(token);
	return await apiBackInstance.put<AlertaResp>("editAlert", {
		alertId,
		precio,
		tipoAlerta,
	});
}

export async function deleteAlertApi(alertId: string, token: string) {
	setToken(token);
	return await apiBackInstance.put("deleteAlert", { alertId });
}

export async function getPregFrecuentes(token: string) {
	setToken(token);
	return await apiBackInstance.get<RespPregFrecuentes>('pregFrecuentes');
}

export async function getMovimientos(
	token: string,
	moneda: string,
	fechaDesde: string,
	fechaHasta: string,
	accountSearched?: string,
) {
	const params = {
		moneda,
		fechaDesde,
		fechaHasta,
		accountSearched,
	};
	setToken(token);
	return await apiBackInstance.get<RespMovimientos>('movimientos', { params });
}

export async function getMovimientosTitulos(
	token: string,
	fechaDesde: string,
	fechaHasta: string,
	especie: string,
	accountSearched?: string,
) {
	const params = {
		fechaDesde,
		fechaHasta,
		especie,
		accountSearched,
	};
	setToken(token);
	return await apiBackInstance.get<RespMovimientos>('movimientosTitulos', { params });
}


export async function getDocumentoMovimiento(
	token: string,
	id: string,
) {
	setToken(token);
	return await apiBackInstance.get<any>(`comprobante/${id}`, {
		responseType: 'arraybuffer',
	});
}

export async function getUserCuentas(token: string, accountSearched?: string) {
	setToken(token);
	let params = {
		accountSearched
	}
	return await apiBackInstance.get<RespGetCuentas>('cuentasBancarias', { params });
}

export async function retirarDinero(
	token: string,
	importe: string,
	cbu: string,
	tipoMoneda: string,
	accountSearched?: string,
) {
	setToken(token);
	return await apiBackInstance.post<any>('retirarDinero', { importe, cbu, tipoMoneda, accountSearched });
}


export async function agregarCuenta(
	{
		token,
		moneda,
		cbu,
		tipoCuenta,
		nroCuenta,
		cuit,
		alias,
		accountSearched,
	}: {
		token: string;
		moneda: number;
		cbu: string;
		tipoCuenta: number;
		nroCuenta: number;
		cuit: number;
		alias: string;
		accountSearched?: string;
	}
) {
	setToken(token);
	return await apiBackInstance.post<any>('altaCbu', {
		moneda,
		cbu,
		tipoCuenta,
		nroCuenta,
		cuit,
		alias,
		accountSearched
	});
}

export async function retirarDolares(
	{
		token,
		especie,
		cantidad,
		tipoDolar,
		accountSearched,
	}: {
		token: string;
		especie: string;
		cantidad: number;
		tipoDolar: string;
		accountSearched?: string;
	}
) {
	setToken(token);
	return await apiBackInstance.post<RetirarDolares>('transferirDolarRenta', {
		cantidad,
		especie,
		tipoDolar,
		accountSearched,
	});
}

export async function getTenenciaPorFecha(
	token: string,
	fecha: string,
) {
	const params = {
		fecha,
	};
	setToken(token);
	return await apiBackInstance.get<TenenciaValorizada>('tenenciaFecha', { params });
}