import { useNavigate } from 'react-router-dom';
import { AlertaText, AlertaTitleRow, Button, ButtonText, Container, DescText, FechaText, Img, Linea, NoticiaText, PrecioText, Row } from './elements';
import { TipoMercado } from '../../../enums/enums';

interface Props {
  tipo?: string;
  titulo: string;
  fecha: string;
  precio?: number;
  desc?: string;
  img?: string;
  mercado?: string;
}

export const Notificacion = ({
  tipo,
  titulo,
  fecha,
  precio,
  desc,
  img, 
  mercado
}: Props) => {

  const navigate = useNavigate();
  function cutName(name: string) {
    const searchTerm = '(';
    const indexOf = name.indexOf(searchTerm);
    return indexOf === -1 ? name : name.slice(0, indexOf);
  }

  return (
    <>
      <Container>
        <Row>
          {tipo !== 'Alerta' ? (
            <NoticiaText>{titulo}</NoticiaText>
          ) : (
            <AlertaTitleRow>
              <Img
                src={
                  mercado === TipoMercado.Exterior
                    ? require('../../../images/bandera-usa.png')
                    : require('../../../images/bandera-argentina-cuenta.png')
                }
              />
              <AlertaText>{cutName(titulo)}</AlertaText>
            </AlertaTitleRow>
          )}
          <FechaText>{fecha}</FechaText>
        </Row>
        <div
          style={{flexDirection: 'row', justifyContent: 'space-between', margin: "8 0"}}>
          <div>
            {tipo !== 'Alerta' ? (
              <DescText>{desc?.length! > 35 ? `${desc?.slice(0, 35)}...` : desc}</DescText>
            ) : precio ? (
              <PrecioText>
                $ {precio}
              </PrecioText>
            ) : null}
          </div>
        </div>
        {tipo !== 'Alerta' ? (
          <Button
            onClick={() =>
              navigate('/detalle-notificacion', {
                state: {
                  seccion: 'NOTICIAS',
                  fecha,
                  titulo,
                  texto: desc,
                  img,
                }
              })
            }
          >
            <ButtonText>Ver más</ButtonText>
          </Button>
        ) : null}
        
      </Container>
      </>
  );
};